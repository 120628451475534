import { ConfigProvider } from 'antd'
import EN from 'antd/es/locale/en_GB'
import dayjs from 'dayjs'
import { FC, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Outlet } from 'react-router-dom'
import AdminLayout from '../layouts/AdminLayout'
import { APP_LANGUAGES } from '../utils/enums'

const AdminRoutes: FC = () => {
	const { i18n } = useTranslation()

	useEffect(() => {
		i18n.changeLanguage(APP_LANGUAGES.EN)
		dayjs.locale('en-gb')
	}, [i18n])

	return i18n.language === APP_LANGUAGES.EN ? (
		<ConfigProvider locale={EN}>
			<AdminLayout>
				<Outlet />
			</AdminLayout>
		</ConfigProvider>
	) : null
}

export default AdminRoutes
