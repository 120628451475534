import { ConfigProvider } from 'antd'
import SK from 'antd/es/locale/sk_SK'
import dayjs from 'dayjs'
import { FC, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Outlet } from 'react-router-dom'
import PublicLayout from '../layouts/PublicLayout'
import { APP_LANGUAGES } from '../utils/enums'

const PublicRoutes: FC<{ hideTitle?: boolean }> = ({ hideTitle = false }) => {
	const { i18n } = useTranslation()

	useEffect(() => {
		i18n.changeLanguage(APP_LANGUAGES.SK)
		dayjs.locale('sk')
	}, [i18n])

	return i18n.language === APP_LANGUAGES.SK ? (
		<ConfigProvider locale={SK}>
			<PublicLayout hideTitle={hideTitle}>
				<Outlet />
			</PublicLayout>
		</ConfigProvider>
	) : null
}

export default PublicRoutes
