import { Button, Col, Drawer, Row } from 'antd'
import { FC, PropsWithChildren, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useMediaQuery } from 'react-responsive'
import { ReactComponent as CrossIcon } from '../assets/icons/cross.svg'
import { ReactComponent as HelpIcon } from '../assets/icons/help.svg'
import { ReactComponent as PersonIcon } from '../assets/icons/person.svg'
import { ReactComponent as PlusIcon } from '../assets/icons/plus.svg'
import UserAvatar from '../atoms/UserAvatar'
import { RootState } from '../redux'
import { employeesLoadDetail, IEmployeesLoadDetailPayload } from '../redux/employees/employeesActions'
import { withdrawalsCreate } from '../redux/withdrawals/withdrawalsActions'
import { WITHDRAWAL_STATE } from '../utils/enums'
import CreateWithdrawalForm, { ICreateWithdrawalFormValues } from './employee/CreateWithdrawalForm'
import HelpModal from './shared/HelpModal'
import LogoutPopover from './shared/LogoutPopover'

type IEmployeeLayout = PropsWithChildren

const EmployeeLayout: FC<IEmployeeLayout> = ({ children }) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const isMobile = useMediaQuery({ maxWidth: 991 })
	const isDesktop = useMediaQuery({ minWidth: 992 })

	const employee: IEmployeesLoadDetailPayload = useSelector((state: RootState) => state.employees.detail)

	const [helpModalVisible, setHelpModalVisible] = useState<boolean>(false)
	const [createWithdrawalDrawerVisible, setCreateWithdrawalDrawerVisible] = useState(false)
	const [createWithdrawalFormSubmitSuccess, setCreateWithdrawalFormSubmitSuccess] = useState(false)

	const handleCreateWithdrawalDrawerClose = () => {
		setCreateWithdrawalDrawerVisible(false)
		setCreateWithdrawalFormSubmitSuccess(false)
		if (!employee.record) return
		dispatch(employeesLoadDetail(employee.record.id))
	}

	const handleCreateWithdrawalFormSubmit = async (values: ICreateWithdrawalFormValues) => {
		if (!employee.record) return
		await dispatch(
			withdrawalsCreate(employee.record.id, values, () => {
				setCreateWithdrawalFormSubmitSuccess(true)
			})
		)
	}

	return (
		<>
			{/* Header */}
			<header className={'fixed top-0 left-0 right-0 z-10 bg-white lg:shadow-md py-6 px-4 lg:px-8 xl:px-5% xxl:px-10%'}>
				<Row justify={'space-between'} align={'middle'}>
					<Col xs={6} lg={0} hidden={isDesktop} />
					<Col xs={12} lg={12} className={'inline-flex justify-center lg:justify-start'}>
						<h1 className={'text-26 font-700'}>{t('loc:general|WageNow')}</h1>
					</Col>
					<Col xs={6} lg={12} className={'inline-flex justify-end items-center gap-4 lg:gap-8'}>
						<Button
							type={'primary'}
							shape={'round'}
							size={'small'}
							onClick={() => setCreateWithdrawalDrawerVisible(true)}
							hidden={employee.record?.withdrawalState === WITHDRAWAL_STATE.PENDING}
							id={'create-withdrawal-drawer-open-button'}
							style={{ visibility: !isDesktop ? 'hidden' : 'visible' }}
						>
							<PlusIcon />
							{t('loc:employee|Withdrawal')}
						</Button>
						<Button type={'text'} icon={<HelpIcon className={'text-24 text-gray-500'} />} onClick={() => setHelpModalVisible(true)} />
						<LogoutPopover>{isMobile ? <PersonIcon className={'text-24 text-gray-500'} /> : <UserAvatar showName={true} />}</LogoutPopover>
					</Col>
				</Row>
			</header>
			{/* Content */}
			<section className={'flex-grow-1 mt-[5.5rem] bg-white lg:bg-purple-light py-4 lg:py-12 px-4 lg:px-8 xl:px-5% xxl:px-10%'}>{children}</section>
			{/* Modals */}
			<HelpModal visible={helpModalVisible} setVisible={setHelpModalVisible} />
			<Drawer
				placement={'bottom'}
				height={'100%'}
				visible={createWithdrawalDrawerVisible}
				closeIcon={<CrossIcon />}
				onClose={handleCreateWithdrawalDrawerClose}
				destroyOnClose={true}
				className={'create-withdrawal-drawer'}
			>
				<CreateWithdrawalForm
					initialValues={{ amount: 0, availableEarnings: employee.record?.availableEarnings }}
					handleCreateWithdrawalDrawerClose={handleCreateWithdrawalDrawerClose}
					createWithdrawalFormSubmitSuccess={createWithdrawalFormSubmitSuccess}
					onSubmit={handleCreateWithdrawalFormSubmit}
				/>
			</Drawer>
		</>
	)
}

export default EmployeeLayout
