import { combineReducers } from '@reduxjs/toolkit'
import { reducer as formReducer } from 'redux-form'
import { persistReducer } from 'redux-persist'
import sessionStorage from 'redux-persist/es/storage/session'
import localStorage from 'redux-persist/lib/storage'
import { ThunkAction } from 'redux-thunk'

import companiesReducer from './companies/companiesReducer'
import employeesReducer from './employees/employeesReducer'
import onboardingReducer from './onboarding/onboardingReducer'
import userAuthReducer from './userAuth/userAuthReducer'
import withdrawalsReducer from './withdrawals/withdrawalsReducer'

const REDUCER_KEYS = {
	FORM: 'form',
	USER_AUTH: 'user_auth',
	COMPANIES: 'companies',
	EMPLOYEES: 'employees',
	WITHDRAWALS: 'withdrawals',
	ONBOARDING: 'onboarding'
}

const rootReducer = combineReducers({
	form: persistReducer(
		{
			key: REDUCER_KEYS.FORM,
			storage: sessionStorage
		},
		formReducer
	),
	userAuth: persistReducer(
		{
			key: REDUCER_KEYS.USER_AUTH,
			storage: localStorage
		},
		userAuthReducer
	),
	companies: persistReducer(
		{
			key: REDUCER_KEYS.COMPANIES,
			storage: sessionStorage
		},
		companiesReducer
	),
	employees: persistReducer(
		{
			key: REDUCER_KEYS.EMPLOYEES,
			storage: sessionStorage
		},
		employeesReducer
	),
	withdrawals: persistReducer(
		{
			key: REDUCER_KEYS.WITHDRAWALS,
			storage: sessionStorage
		},
		withdrawalsReducer
	),
	onboarding: persistReducer(
		{
			key: REDUCER_KEYS.ONBOARDING,
			storage: sessionStorage
		},
		onboardingReducer
	)
})

export default rootReducer
export type RootState = ReturnType<typeof rootReducer>
export type ThunkResult<R> = ThunkAction<R, RootState, undefined, any>
