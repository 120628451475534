import { Input, InputProps } from 'antd'
import cx from 'classnames'
import { ChangeEvent, FC, memo } from 'react'
import { WrappedFieldInputProps, WrappedFieldMetaProps } from 'redux-form'
import { formFieldID } from '../../utils/helpers'
import { ReactComponent as ErrorIcon } from '../../assets/icons/error.svg'
import { sanitizeNumber } from '../../utils/numberUtils'

interface INumberInputField extends InputProps {
	label?: string
	input: Partial<WrappedFieldInputProps>
	meta: Partial<WrappedFieldMetaProps>
	inputClassName?: string
	setZeroForNoValue?: boolean
	roundTo?: number
}

const NumberInputField: FC<INumberInputField> = ({
	label,
	placeholder,
	disabled,
	className,
	style,
	input,
	meta,
	inputClassName,
	setZeroForNoValue,
	roundTo,
	...props
}) => {
	const { name = 'field', value = undefined, onChange = undefined } = input
	const { form = 'form', error = undefined, touched = false } = meta

	const onAmountChange = (event: ChangeEvent<HTMLInputElement>) => {
		const amount = sanitizeNumber(event.target.value)

		if (onChange) {
			onChange(amount)
		}
	}

	const id = formFieldID(form, name)

	return (
		<div className={cx('form-field', className)} style={style}>
			{label && (
				<label htmlFor={id} className={'form-field-label'}>
					{label}
				</label>
			)}
			<div className={'form-field-item'}>
				<div className={'ant-input-number'}>
					<div className={'ant-input-number-input-wrap'}>
						<Input
							id={id}
							placeholder={placeholder}
							disabled={disabled}
							value={value || ''}
							onChange={onAmountChange}
							className={cx(inputClassName, 'ant-input-number-input', { error: error && touched })}
							{...props}
						/>
						<span className={'form-field-item-suffix'}>{error && touched && <ErrorIcon />}</span>
					</div>
				</div>
			</div>
			{touched && (
				<span className={'form-field-message error'}>
					{error}
					&nbsp;
				</span>
			)}
		</div>
	)
}

export default memo(NumberInputField)
