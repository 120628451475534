import { Button, Col, Form, Row } from 'antd'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Field, InjectedFormProps, reduxForm } from 'redux-form'
import InputField from '../../../atoms/form/InputField'
import SwitchField from '../../../atoms/form/SwitchField'
import { FORM } from '../../../utils/enums'
import validateResetPasswordForm from './validateResetPasswordForm'

export interface IResetPasswordFormValues {
	email: string
	password: string
	confirmPassword: string
	consent: boolean
}

const ResetPasswordForm: FC<InjectedFormProps<IResetPasswordFormValues>> = ({ handleSubmit, submitting }) => {
	const { t } = useTranslation()

	return (
		<Form noValidate={true} onSubmitCapture={handleSubmit}>
			<Field name={'password'} component={InputField} type={'password'} placeholder={t('loc:general|Password')} required className={'mb-4'} />
			<Field
				name={'confirmPassword'}
				component={InputField}
				type={'password'}
				placeholder={t('loc:general|Confirm password')}
				required
				className={'mb-4'}
			/>
			<Row justify={'space-between'} align={'middle'} className={'mt-10 mb-6'}>
				<Col span={'16'}>
					<span className={'inline-block text-13 break-words'}>
						{`${t('loc:general|I agree with')} `}
						<a href={'https://www.wagenow.sk/vop'} className={'underline'} target={'_blank'} rel={'noopener noreferrer'}>
							{t('loc:general|with Terms and Conditions')}
						</a>
					</span>
				</Col>
				<Col span={'8'} className={'text-right'}>
					<Field name={'consent'} component={SwitchField} required />
				</Col>
			</Row>
			<Button type={'primary'} shape={'round'} block={true} htmlType={'submit'} loading={submitting}>
				{t('loc:general|Submit')}
			</Button>
		</Form>
	)
}

export default reduxForm<IResetPasswordFormValues>({
	form: FORM.RESET_PASSWORD,
	validate: validateResetPasswordForm,
	touchOnChange: true,
	destroyOnUnmount: true,
	forceUnregisterOnUnmount: true
})(ResetPasswordForm)
