import { Col, Row, Spin } from 'antd'
import { FC, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useMediaQuery } from 'react-responsive'
import { RootState } from '../../redux'
import { employeesLoadDetail, IEmployeesLoadDetailPayload } from '../../redux/employees/employeesActions'
import { IUser } from '../../types/interfaces'
import WithdrawalsDashboardButton from './dashboard/WithdrawalsDashboardButton'
import WithdrawalsDashboardHistory from './dashboard/WithdrawalsDashboardHistory'
import WithdrawalsDashboardOverview from './dashboard/WithdrawalsDashboardOverview'

type IWithdrawalsDashboard = {}

const WithdrawalsDashboard: FC<IWithdrawalsDashboard> = () => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const isMobile = useMediaQuery({ maxWidth: 991 })
	const isDesktop = useMediaQuery({ minWidth: 992 })

	const user: IUser | undefined = useSelector((state: RootState) => state.userAuth.user)
	const employee: IEmployeesLoadDetailPayload | undefined = useSelector((state: RootState) => state.employees.detail)

	useEffect(() => {
		document.title = `${t('loc:employee|Withdrawals')} - ${t('loc:general|WageNow')}`
	})

	useEffect(() => {
		if (!user) return
		dispatch(employeesLoadDetail(user.id))
	}, [dispatch, user])

	if (!employee.record) return null

	return employee.isLoading ? (
		<div className={'h-80 flex justify-center items-center'}>
			<Spin size={'large'} />
		</div>
	) : (
		<Row justify={'center'}>
			<Col xs={{ span: 24 }} xxl={{ span: 20 }}>
				<Row gutter={{ lg: 32, xxl: 64 }}>
					<Col xs={{ span: 24 }} lg={{ span: 12 }} className={'mb-6 lg:mb-0'}>
						<div className={'lg:bg-white lg:p-10 lg:rounded-md'}>
							<WithdrawalsDashboardOverview />
							{isMobile && <WithdrawalsDashboardButton />}
							<WithdrawalsDashboardHistory />
						</div>
					</Col>
					{isDesktop && (
						<Col xs={{ span: 24 }} lg={{ span: 12 }}>
							<WithdrawalsDashboardButton />
						</Col>
					)}
				</Row>
			</Col>
		</Row>
	)
}

export default WithdrawalsDashboard
