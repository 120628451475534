import { ThunkResult } from '..'
import { getReq, postReq } from '../../utils/requests'
import { GET_ONBOARDING_DATA, POST_ONBOARDING_DATA } from './onboardingTypes'

export const ContractFlows = ['CONTRACT_ON_FIRST_WITHDRAWAL', 'CONTRACT_ON_REGISTRATION', 'CONTRACT_BEFORE_REGISTRATION'] as const
export type ContractFlow = typeof ContractFlows[number]

export interface IData {
	name: string
	companyHash: string
	companyContractFlow?: ContractFlow
	id: number
	onboardingForm: {
		bgColor: string
		bgPicture: string
		description: string
		id: number
		isVisible: boolean
		logo: string
		paragraph: string
		title: string
		additionalFields: Array<{
			fieldName: string
			isRequired: boolean
			label: string
			placeholder: string
		}>
	}
}
interface IOnboardingData {
	type: GET_ONBOARDING_DATA
	payload: IData
}

export type IOnboardingDataAction = IOnboardingData

// eslint-disable-next-line import/prefer-default-export
export const getOnboardingData =
	(hash: string, onSuccess: () => void, onFail: () => void): ThunkResult<void> =>
	async (dispatch) => {
		dispatch({ type: GET_ONBOARDING_DATA.START })
		try {
			const { data } = await getReq(`/api/v1/companies/${hash}/onboarding-form` as any, undefined, undefined, false)
			dispatch({
				payload: data,
				type: GET_ONBOARDING_DATA.DONE
			})
			onSuccess()
		} catch (error) {
			onFail()
			dispatch({ type: GET_ONBOARDING_DATA.FAIL })
		}
	}

export const postOnboardingData =
	(values: any, companyHash: string, onSuccess?: () => void): ThunkResult<void> =>
	async (dispatch) => {
		dispatch({ type: POST_ONBOARDING_DATA.START })
		try {
			await postReq(`/api/v1/companies/${companyHash}/users` as any, undefined, values)
			dispatch({ type: POST_ONBOARDING_DATA.DONE })
			if (onSuccess) onSuccess()
		} catch (error) {
			dispatch({ type: POST_ONBOARDING_DATA.FAIL })
		}
	}
