import { Button, Result } from 'antd'
import { FC, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

const NotFoundPage: FC = () => {
	const { t } = useTranslation()
	const navigate = useNavigate()

	useEffect(() => {
		document.title = `${t('loc:general|Page not found')} - ${t('loc:general|WageNow')}`
	})

	return (
		<div className={'h-100% flex justify-center items-center m-4'}>
			<Result
				status={'404'}
				title={'404'}
				subTitle={`${t('loc:general|This page was not found')}.`}
				extra={
					<Button type={'primary'} shape={'round'} onClick={() => navigate(-1)}>
						{'Go back'}
					</Button>
				}
			/>
		</div>
	)
}

export default NotFoundPage
