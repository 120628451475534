import { Button, Input, InputProps } from 'antd'
import cx from 'classnames'
import { FC, memo, useState } from 'react'
import { WrappedFieldInputProps, WrappedFieldMetaProps } from 'redux-form'
import { ReactComponent as ErrorIcon } from '../../assets/icons/error.svg'
import { ReactComponent as EyeClosedIcon } from '../../assets/icons/eye-closed.svg'
import { ReactComponent as EyeOpenedIcon } from '../../assets/icons/eye-opened.svg'
import { ReactComponent as SearchIcon } from '../../assets/icons/search.svg'
import { formFieldID } from '../../utils/helpers'

interface IInputField extends InputProps {
	label?: string
	input: Partial<WrappedFieldInputProps>
	meta: Partial<WrappedFieldMetaProps>
	inputClassName?: string
}

const InputField: FC<IInputField> = ({ label, placeholder, disabled, className, style, input, meta, inputClassName, type, maxLength = 255, ...props }) => {
	const { name = 'field', value = undefined, onChange = undefined } = input
	const { form = 'form', error = undefined, touched = false } = meta

	const id = formFieldID(form, name)
	const [inputType, setInputType] = useState(type)

	return (
		<div className={cx('form-field', className)} style={style}>
			{label && (
				<label htmlFor={id} className={'form-field-label'}>
					{label}
				</label>
			)}
			<div className={'form-field-item'}>
				<Input
					id={id}
					placeholder={placeholder}
					disabled={disabled}
					value={value || undefined}
					onChange={onChange}
					type={inputType}
					maxLength={maxLength}
					className={cx(inputClassName, { error: error && touched })}
					{...props}
				/>
				<span className={'form-field-item-suffix'}>
					{error && touched ? (
						<ErrorIcon />
					) : (
						<>
							{type === 'password' && (
								<Button
									type={'text'}
									shape={'round'}
									onClick={() => setInputType(inputType === 'password' ? 'text' : 'password')}
									icon={inputType === 'password' ? <EyeOpenedIcon /> : <EyeClosedIcon />}
									tabIndex={-1}
								/>
							)}
							{type === 'search' && <SearchIcon />}
						</>
					)}
				</span>
			</div>
			{error && touched && <span className={'form-field-message'}>{error}</span>}
		</div>
	)
}

export default memo(InputField)
