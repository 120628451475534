import { Button, Empty, Spin, Table, TableColumnProps } from 'antd'
import cx from 'classnames'
import { FC, MouseEvent, useEffect, useLayoutEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { ReactComponent as ApproveIcon } from '../../../assets/icons/approve.svg'
import { ReactComponent as RejectIcon } from '../../../assets/icons/reject.svg'
import StateItem from '../../../atoms/StateItem'
import UserAvatar from '../../../atoms/UserAvatar'
import { RootState } from '../../../redux'
import { companiesLoadList } from '../../../redux/companies/companiesActions'
import { employeesLoadDetail, employeesUpdate, IEmployeesLoadListPayload } from '../../../redux/employees/employeesActions'
import { IEmployee } from '../../../types/interfaces'
import { ACCOUNT_STATE } from '../../../utils/enums'
import { IEmployeesListFilterValues } from './EmployeesListFilter'
import EmployeesListTableCell from './EmployeesListTableCell'

export interface IEmployeesListTableColumn extends TableColumnProps<IEmployee> {
	editable: boolean
}

interface IEmployeesListTable {
	filter: IEmployeesListFilterValues
	setFilter: (filter: IEmployeesListFilterValues) => void
}

const EmployeesListTable: FC<IEmployeesListTable> = ({ filter, setFilter }) => {
	const { t } = useTranslation()
	const navigate = useNavigate()
	const dispatch = useDispatch()

	const list: IEmployeesLoadListPayload = useSelector((state: RootState) => state.employees.list)

	const [dataSource, setDataSource] = useState<IEmployeesLoadListPayload['records']>(list.records)
	const [editedRecordIDs, setEditedRecordIDs] = useState<Set<IEmployee['id']>>(new Set())

	const handleRecordEdit = (value: unknown, column: IEmployeesListTableColumn, record: IEmployee) => {
		const newDataSource = dataSource ? [...dataSource] : []
		const recordIndex = newDataSource?.findIndex((item) => item.id === record.id)
		newDataSource.splice(recordIndex, 1, { ...record, ...{ [column.dataIndex as string]: value } })
		setDataSource(newDataSource)
		const newEditedRecordIDs = editedRecordIDs
		newEditedRecordIDs.add(record.id)
		setEditedRecordIDs(new Set(newEditedRecordIDs))
	}

	const handleRecordSave = async (event: MouseEvent<HTMLElement>, record: IEmployee) => {
		event.stopPropagation()
		await dispatch(
			employeesLoadDetail(record.id, (employeeDetail) => {
				dispatch(
					employeesUpdate(
						{ ...employeeDetail.record, ...record },
						() => {
							const newEditedRecordIDs = editedRecordIDs
							newEditedRecordIDs.delete(record.id)
							setEditedRecordIDs(new Set(newEditedRecordIDs))
						},
						true
					)
				)
			})
		)
	}

	const columns: IEmployeesListTableColumn[] = [
		{
			title: t('loc:admin|Name and Company'),
			key: 'user',
			render: (record: IEmployee) => (
				<UserAvatar user={record} showName={true}>
					{record.company && <span className={'text-12 truncate'}>{record.company.name}</span>}
				</UserAvatar>
			),
			width: 280,
			className: 'max-w-[332px]',
			ellipsis: true,
			editable: false
		},
		{
			title: t('loc:admin|Available Earnings'),
			key: 'availableEarnings',
			dataIndex: 'availableEarnings',
			width: 180,
			ellipsis: true,
			editable: true
		},
		{
			title: t('loc:admin|Current Period Start'),
			key: 'periodStartDate',
			dataIndex: 'periodStartDate',
			width: 170,
			className: 'min-w-[170px]',
			ellipsis: true,
			editable: true
		},
		{
			title: t('loc:admin|Current Period End'),
			key: 'periodEndDate',
			dataIndex: 'periodEndDate',
			width: 170,
			className: 'min-w-[170px]',
			ellipsis: true,
			editable: true
		},
		{
			title: t('loc:general|Next Payday'),
			key: 'nextPayDate',
			dataIndex: 'nextPayDate',
			width: 170,
			className: 'min-w-[170px]',
			ellipsis: true,
			editable: true
		},
		{
			title: t('loc:admin|Request'),
			key: 'withdrawalState',
			render: (record: IEmployee) => <StateItem state={record.withdrawalState} nullStateLabel={'No withdrawals'} />,
			width: 184,
			ellipsis: true,
			editable: false
		},
		{
			title: t('loc:admin|State'),
			key: 'accountState',
			dataIndex: 'accountState',
			render: (value: IEmployee['accountState']) => <StateItem state={value === ACCOUNT_STATE.DEACTIVATED ? ACCOUNT_STATE.INACTIVE : value} />,
			width: 104,
			ellipsis: true,
			editable: false
		},
		{
			title: t('loc:admin|Disabled'),
			key: 'deactivated',
			render: (record: IEmployee) => (
				<div className={'inline-flex items-center gap-6 mt-2'}>
					<Button
						type={'text'}
						icon={<RejectIcon />}
						className={cx('text-24', { 'opacity-0': record.accountState !== ACCOUNT_STATE.DEACTIVATED })}
						onClick={(event) => event.stopPropagation()}
					/>
					<Button
						type={'text'}
						icon={<ApproveIcon />}
						className={cx('text-24', { 'opacity-0': !editedRecordIDs.has(record.id) })}
						onClick={(event) => handleRecordSave(event, record)}
					/>
				</div>
			),
			width: 72,
			editable: false
		}
	]

	useEffect(() => {
		dispatch(companiesLoadList())
	}, [dispatch])

	useLayoutEffect(() => {
		setDataSource(list.records)
	}, [list.records])

	return (
		<Table
			scroll={{ x: true }}
			loading={{
				indicator: <Spin size={'large'} />,
				spinning: list.isLoading
			}}
			columns={columns.map((column) => ({
				...column,
				onCell: (record: IEmployee) => ({
					onClick: (event) => column.editable && event.stopPropagation(),
					record,
					column,
					handleChange: (value: unknown) => handleRecordEdit(value, column, record)
				})
			}))}
			dataSource={dataSource}
			rowKey={(record) => record.id}
			onRow={(record) => ({
				onClick: () => navigate(`${t('paths:employees')}/${record.id}`)
			})}
			rowClassName={(record) => (editedRecordIDs.has(record.id) ? 'bg-cream' : '')}
			locale={{ emptyText: <Empty description={!list.isLoading && t('loc:general|No Data')} /> }}
			pagination={{
				position: ['bottomCenter'],
				showSizeChanger: false,
				hideOnSinglePage: true,
				total: list.pagination?.totalCount,
				current: list.pagination?.page,
				pageSize: list.pagination?.limit
			}}
			onChange={(pagination) => setFilter({ ...filter, page: pagination.current })}
			components={{ body: { cell: EmployeesListTableCell } }}
			className={'employees-list-table'}
		/>
	)
}
export default EmployeesListTable
