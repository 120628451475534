import { IStoreAction, STORE } from '../generalTypes'
import { IOnboardingDataAction, IData } from './onboardingActions'
import { GET_ONBOARDING_DATA } from './onboardingTypes'

interface IState {
	data: IData | undefined
	isLoading: boolean
	isFailure: boolean
}

const initState: IState = {
	data: undefined,
	isLoading: false,
	isFailure: false
}

export default (state = initState, action: IOnboardingDataAction | IStoreAction) => {
	switch (action.type) {
		case GET_ONBOARDING_DATA.START:
			return {
				...state,
				isLoading: true,
				isFailure: false
			}
		case GET_ONBOARDING_DATA.DONE:
			return {
				data: action.payload,
				isLoading: false,
				isFailure: false
			}
		case GET_ONBOARDING_DATA.FAIL:
			return {
				...state,
				isLoading: false,
				isFailure: true
			}
		case STORE.RESET:
			return initState
		default:
			return state
	}
}
