import { applyMiddleware, compose, createStore, Reducer } from 'redux'
import { createLogger } from 'redux-logger'
import { persistStore } from 'redux-persist'
import thunk from 'redux-thunk'

const loggerFilter = (getState: any, action: any) => {
	if (action.type.startsWith('persist')) return false
	if (action.type === '@@redux-form/REGISTER_FIELD') return false
	return action.type !== '@@redux-form/UNREGISTER_FIELD'
}

const configureStoreProd = (rootReducer: Reducer) => {
	const middlewares = [thunk]
	const store = createStore(rootReducer, compose(applyMiddleware(...middlewares)))
	const persistor = persistStore(store)
	return { store, persistor }
}

const configureStoreDev = (rootReducer: Reducer) => {
	const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
	const middlewares = [
		thunk,
		createLogger({
			collapsed: true,
			duration: true,
			predicate: loggerFilter
		})
	]
	const store = createStore(rootReducer, composeEnhancers(applyMiddleware(...middlewares)))
	const persistor = persistStore(store)
	return { store, persistor }
}

export default (rootReducer: Reducer) => (process.env.NODE_ENV === 'production' ? configureStoreProd(rootReducer) : configureStoreDev(rootReducer))
