import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { employeesLoadList } from '../../redux/employees/employeesActions'
import EmployeesListFilter, { employeesListFilterDefaultState, IEmployeesListFilterValues } from './list/EmployeesListFilter'
import EmployeesListTable from './list/EmployeesListTable'

const EmployeesList: FC = () => {
	const { t } = useTranslation()
	const dispatch = useDispatch()

	const [filter, setFilter] = useState<IEmployeesListFilterValues>(employeesListFilterDefaultState)

	useEffect(() => {
		document.title = `${t('loc:admin|Employees')} - ${t('loc:general|WageNow')}`
	})

	useEffect(() => {
		dispatch(employeesLoadList(filter))
	}, [dispatch, filter])

	return (
		<>
			<EmployeesListFilter filter={filter} setFilter={setFilter} />
			<EmployeesListTable filter={filter} setFilter={setFilter} />
		</>
	)
}

export default EmployeesList
