import { Button } from 'antd'
import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import HelpModal from '../../layouts/shared/HelpModal'
import { userAuthLogin } from '../../redux/userAuth/userAuthActions'
import LoginForm, { ILoginFormValues } from './login/LoginForm'
import ErrorModal from '../../layouts/shared/ErrorModal'

const LoginPage: FC = () => {
	const { t } = useTranslation()
	const navigate = useNavigate()
	const dispatch = useDispatch()

	const [forgotEmailModalVisible, setForgotEmailModalVisible] = useState(false)
	const [errorModalVisible, setErrorModalVisible] = useState(false)

	useEffect(() => {
		document.title = `${t('loc:general|Login')} - ${t('loc:general|WageNow')}`
	})

	const handleLoginFormSubmit = async (values: ILoginFormValues) => {
		await dispatch(
			userAuthLogin(
				values,
				() => navigate(t('paths:withdrawals')),
				() => setErrorModalVisible(true)
			)
		)
	}

	return (
		<>
			<span className={'block text-28 font-700 mb-4'}>{t('loc:general|Login')}</span>
			<LoginForm onSubmit={handleLoginFormSubmit} />
			<div className={'text-center mt-4'}>
				<Button type={'text'} className={'text-15 font-400 text-gradient'} onClick={() => setForgotEmailModalVisible(true)}>
					{`${t('loc:general|Forgot email')}?`}
				</Button>
			</div>

			<HelpModal visible={forgotEmailModalVisible} setVisible={setForgotEmailModalVisible} />
			<ErrorModal visible={errorModalVisible} setVisible={setErrorModalVisible} />
		</>
	)
}

export default LoginPage
