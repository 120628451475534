import i18next from 'i18next'
import { FC, PropsWithChildren } from 'react'
import DatePickerField from '../../../atoms/form/DatePickerField'
import NumberInputField from '../../../atoms/form/NumberInputField'
import { IEmployee } from '../../../types/interfaces'
import { IEmployeesListTableColumn } from './EmployeesListTable'

const renderField = ({ record, column, handleChange }: IEmployeesListTableCell) => {
	switch (column.dataIndex) {
		case 'availableEarnings':
			return (
				<NumberInputField
					placeholder={i18next.t('loc:admin|Enter Amount')}
					input={{
						name: column.dataIndex,
						value: record[column.dataIndex] || undefined,
						onChange: (value) => handleChange(value)
					}}
					setZeroForNoValue={true}
					meta={{ form: `employees-list-table-record-${record.id}` }}
				/>
			)
		case 'periodStartDate':
		case 'periodEndDate':
		case 'nextPayDate':
			return (
				<DatePickerField
					placeholder={i18next.t('loc:admin|Select Date')}
					input={{
						name: column.dataIndex,
						value: record[column.dataIndex] || undefined,
						onChange: (value) => handleChange(value)
					}}
					meta={{ form: `employees-list-table-record-${record.id}` }}
					showToday={false}
				/>
			)
		default:
			return null
	}
}

interface IEmployeesListTableCell extends PropsWithChildren {
	record: IEmployee
	column: IEmployeesListTableColumn
	handleChange: (value: unknown) => void
}

const EmployeesListTableCell: FC<IEmployeesListTableCell> = ({ record, column, handleChange, children, ...props }) => {
	return <td {...props}>{column && column.editable ? renderField({ record, column, handleChange }) : children}</td>
}

export default EmployeesListTableCell
