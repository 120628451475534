import { Button } from 'antd'
import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import exclamationMarkImage from '../../assets/images/exclamation-mark.png'
import BasicModal from '../../atoms/BasicModal'
import { userAuthForgotPassword } from '../../redux/userAuth/userAuthActions'
import { isAdminRoute } from '../../utils/helpers'
import ForgotPasswordForm, { IForgotPasswordFormValues } from './forgot-password/ForgotPasswordForm'

const ForgotPasswordPage: FC = () => {
	const { t } = useTranslation()
	const navigate = useNavigate()
	const dispatch = useDispatch()

	const [successModalVisible, setSuccessModalVisible] = useState(false)

	useEffect(() => {
		document.title = `${t('loc:general|Forgot password')} - ${t('loc:general|WageNow')}`
	})

	const handleForgotPasswordFormSubmit = async (values: IForgotPasswordFormValues) => {
		await dispatch(
			userAuthForgotPassword(values, () => {
				setSuccessModalVisible(true)
			})
		)
	}

	const handleSuccessModalClose = () => {
		setSuccessModalVisible(false)
		setTimeout(() => navigate(isAdminRoute ? t('paths:admin/login') : t('paths:login')), 500)
	}

	return (
		<>
			<h2 className={'text-28 font-700 mb-4'}>{`${t('loc:general|Forgot password')}?`}</h2>
			<p className={'text-14 lg:text-17 mb-4'}>{`${t('loc:general|Enter your e-mail address to reset your password')}.`}</p>

			<ForgotPasswordForm onSubmit={handleForgotPasswordFormSubmit} />

			<div className={'text-center mt-4'}>
				<Button
					type={'text'}
					className={'text-15 font-400 text-gradient'}
					onClick={() => navigate(isAdminRoute ? t('paths:admin/login') : t('paths:login'))}
				>
					{t('loc:general|Back to login')}
				</Button>
			</div>

			<BasicModal visible={successModalVisible} onClose={handleSuccessModalClose} width={'30rem'}>
				<img src={exclamationMarkImage} alt={''} className={'absolute z-1 top-0 right-16 transform -translate-y-50%'} style={{ width: '22px' }} />
				<h4 className={'text-28 font-700 mb-6'}>{t('loc:general|Forgotten password')}</h4>
				<p className={'text-15 font-400 mb-8'}>
					{`${t('loc:general|If there is an account in the system for the entered email address, an email with instructions will be sent to it')}.`}
				</p>
				<Button type={'primary'} shape={'round'} block={true} onClick={handleSuccessModalClose}>
					{t('loc:general|OK')}
				</Button>
			</BasicModal>
		</>
	)
}

export default ForgotPasswordPage
