// eslint-disable-next-line import/prefer-default-export
export enum GET_ONBOARDING_DATA {
	START = 'GET_ONBOARDING_DATA_START',
	DONE = 'GET_ONBOARDING_DATA_DONE',
	FAIL = 'GET_ONBOARDING_DATA_FAIL'
}

export enum POST_ONBOARDING_DATA {
	START = 'POST_ONBOARDING_DATA_START',
	DONE = 'POST_ONBOARDING_DATA_DONE',
	FAIL = 'POST_ONBOARDING_DATA_FAIL'
}
