import { Button, Form } from 'antd'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Field, InjectedFormProps, reduxForm } from 'redux-form'
import InputField from '../../../atoms/form/InputField'
import { FORM } from '../../../utils/enums'
import { isAdminRoute } from '../../../utils/helpers'
import validateLoginForm from './validateLoginForm'

export interface ILoginFormValues {
	email: string
	password: string
}

const LoginForm: FC<InjectedFormProps<ILoginFormValues>> = ({ handleSubmit, submitting }) => {
	const { t } = useTranslation()

	return (
		<Form noValidate={true} onSubmitCapture={handleSubmit}>
			<Field name={'email'} component={InputField} placeholder={t('loc:general|Email')} required className={'mb-4'} />
			<Field name={'password'} component={InputField} type={'password'} placeholder={t('loc:general|Password')} required className={'mb-4'} />
			<div className={'text-end mb-8'}>
				<Link to={isAdminRoute ? t(`paths:admin/forgot-password`) : t(`paths:forgot-password`)} className={'text-16 font-600 text-gradient'}>
					{`${t('loc:general|Forgot password')}?`}
				</Link>
			</div>
			<Button type={'primary'} shape={'round'} block={true} htmlType={'submit'} loading={submitting}>
				{t('loc:general|Sign In')}
			</Button>
		</Form>
	)
}

export default reduxForm<ILoginFormValues>({
	form: FORM.LOGIN,
	validate: validateLoginForm,
	touchOnChange: true,
	destroyOnUnmount: true,
	forceUnregisterOnUnmount: true
})(LoginForm)
