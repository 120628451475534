import { ICreateEmployeeFormValues } from '../../layouts/admin/CreateEmployeeForm'
import { employeesListFilterDefaultState, IEmployeesListFilterValues } from '../../pages/employees/list/EmployeesListFilter'
import { IEmployee, ILoadingAndFailure, IPaginationResponse } from '../../types/interfaces'
import { NOTIFICATION_TYPE } from '../../utils/enums'
import { getReq, patchReq, postReq } from '../../utils/requests'
import { ThunkResult } from '../index'
import {
	mapEmployeesCreateRequest,
	mapEmployeesLoadDetailResponse,
	mapEmployeesLoadListRequest,
	mapEmployeesLoadListResponse,
	mapEmployeesUpdateRequest
} from './employeesHelpers'
import { EMPLOYEES_CREATE, EMPLOYEES_LOAD_DETAIL, EMPLOYEES_LOAD_LIST, EMPLOYEES_UPDATE } from './employeesTypes'

/* Load list */

export interface IEmployeesLoadListPayload extends ILoadingAndFailure {
	records?: IEmployee[]
	pagination?: IPaginationResponse
}

interface IEmployeesLoadListAction {
	type: EMPLOYEES_LOAD_LIST
	payload: IEmployeesLoadListPayload
}

export const employeesLoadList =
	(filter: IEmployeesListFilterValues = employeesListFilterDefaultState): ThunkResult<void> =>
	async (dispatch) => {
		try {
			dispatch({ type: EMPLOYEES_LOAD_LIST.START })
			const { data } = await getReq('/api/admin/users/', mapEmployeesLoadListRequest(filter))
			dispatch({ type: EMPLOYEES_LOAD_LIST.DONE, payload: mapEmployeesLoadListResponse(data) })
		} catch (error) {
			dispatch({ type: EMPLOYEES_LOAD_LIST.FAIL })
		}
	}

/* Load detail */

export interface IEmployeesLoadDetailPayload extends ILoadingAndFailure {
	record?: IEmployee
}

interface IEmployeesLoadDetailAction {
	type: EMPLOYEES_LOAD_DETAIL
	payload: IEmployeesLoadDetailPayload
}

export const employeesLoadDetail =
	(id: number, onSuccess?: (payload: Omit<IEmployeesLoadDetailPayload, 'isLoading' | 'isFailure'>) => void, onError?: () => void): ThunkResult<void> =>
	async (dispatch) => {
		try {
			dispatch({ type: EMPLOYEES_LOAD_DETAIL.START })
			const { data } = await getReq(`/api/v1/users/{userID}`, { userID: id })
			const payload = mapEmployeesLoadDetailResponse(data)
			dispatch({ type: EMPLOYEES_LOAD_DETAIL.DONE, payload })
			if (onSuccess) onSuccess(payload)
		} catch (error) {
			dispatch({ type: EMPLOYEES_LOAD_DETAIL.FAIL })
			if (onError) onError()
		}
	}

/* Create */

export const employeesCreate =
	(values: ICreateEmployeeFormValues, onSuccess: () => void): ThunkResult<void> =>
	async (dispatch) => {
		dispatch({ type: EMPLOYEES_CREATE.START })
		try {
			await postReq('/api/admin/users/', undefined, mapEmployeesCreateRequest(values), { headers: { 'accept-language': 'sk' } }, false)
			dispatch({ type: EMPLOYEES_CREATE.DONE })
			onSuccess()
		} catch (error) {
			dispatch({ type: EMPLOYEES_CREATE.FAIL })
		}
	}

/* Update */

export const employeesUpdate =
	(values: IEmployee, onSuccess?: () => void, notification?: boolean): ThunkResult<void> =>
	async (dispatch) => {
		dispatch({ type: EMPLOYEES_UPDATE.START })
		try {
			await patchReq(
				`/api/admin/users/{userID}`,
				{ userID: values.id },
				mapEmployeesUpdateRequest(values),
				{},
				notification ? NOTIFICATION_TYPE.NOTIFICATION : false
			)
			dispatch({ type: EMPLOYEES_UPDATE.DONE })
			if (onSuccess) onSuccess()
		} catch (error) {
			dispatch({ type: EMPLOYEES_UPDATE.FAIL })
		}
	}

/* Actions */

export type IEmployeesAction = IEmployeesLoadListAction | IEmployeesLoadDetailAction
