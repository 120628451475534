/* eslint-disable object-shorthand */

import { Paths } from '../../types/api'
import { mapUserRoleIDToEnum } from '../../utils/helpers'
import { IUserAuthPayload } from './userAuthActions'

export const mapUserAuthLoginResponse = (payload: Paths.PostApiV1AuthLogin.Responses.$200): Partial<IUserAuthPayload> => {
	const {
		profile: {
			userID,
			role: { roleID },
			email,
			firstName,
			lastName
		},
		accessToken,
		refreshToken
	} = payload

	return {
		user: {
			id: userID,
			role: mapUserRoleIDToEnum(roleID),
			email: email,
			firstName: firstName,
			lastName: lastName
		},
		authTokens: {
			accessToken: accessToken,
			refreshToken: refreshToken
		}
	}
}

export const mapUserAuthRefreshTokenResponse = (payload: Paths.PostApiV1AuthRefreshToken.Responses.$200): Partial<IUserAuthPayload> => {
	const { accessToken, refreshToken } = payload
	return {
		authTokens: {
			accessToken: accessToken,
			refreshToken: refreshToken
		}
	}
}
