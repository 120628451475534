import { message as antMessage } from 'antd'
import axios, { AxiosError, AxiosRequestConfig, CancelTokenSource } from 'axios'
import i18next from 'i18next'
import { get, has, isEmpty, split } from 'lodash'
import qs from 'qs'
import { userAuthLogout } from '../redux/userAuth/userAuthActions'
import { PathsDictionary } from '../types/api'
import { IErrorMessage } from '../types/interfaces'
import { getAccessToken, hasAccessToken } from './auth'
import { MSG_TYPE, NOTIFICATION_TYPE } from './enums'
import { hiddenRequestError, isAdminRoute, showNotifications } from './helpers'
import history from './history'

/* Functions */

const buildHeaders = () => {
	const headers: Record<string, string> = {
		'Content-Type': 'application/json',
		Accept: 'application/json',
		'Access-Control-Allow-Credentials': 'true',
		'Cache-Control': 'no-cache, no-store',
		Pragma: 'no-cache',
		'X-Version': process.env.REACT_APP_VERSION as string,
		'accept-language': i18next.language
	}
	if (hasAccessToken()) {
		headers.Authorization = `Bearer ${getAccessToken()}`
	}
	return headers
}

const fullFillURL = (urlTemplate: string, params: any) => {
	const pathParams = []
	const queryParams = { ...(params || {}) }
	const fullfilURL = split(urlTemplate, '/')
		.map((blok) => {
			if (/{[^}]*\}/.test(blok)) {
				const param = blok.replace('{', '').replace('}', '')
				pathParams.push(param)
				delete queryParams[param]
				return get(params, param)
			}
			return blok
		})
		.join('/')
	return { fullfilURL, queryParams }
}

export const showErrorNotifications = (error: AxiosError | Error | unknown, notificationType = NOTIFICATION_TYPE.NOTIFICATION) => {
	let messages = get(error, 'response.data.messages')
	if (get(error, 'response.status') === 401) {
		if (hasAccessToken()) {
			messages = [
				{
					type: MSG_TYPE.INFO,
					message: i18next.t('loc:general|You have been automatically logged out')
				}
			]
		}
		userAuthLogout(false)
		history.push(isAdminRoute ? i18next.t('paths:admin/login') : i18next.t('paths:login'))
		// showNotifications(messages, notificationType)
	} else if (get(error, 'response.status') === 504 || get(error, 'response') === undefined || get(error, 'message') === 'Network Error') {
		messages = [
			{
				type: MSG_TYPE.ERROR,
				message: i18next.t('loc:general|Server connection error')
			}
		]
		showNotifications(messages, notificationType)
	} else {
		messages = isEmpty(messages) ? [{ type: MSG_TYPE.ERROR, message: i18next.t('loc:general|Whoops, something went wrong') }] : messages
		showNotifications(messages, notificationType)
	}
}

/* Requests */

type FilteredKeys<T, U> = { [P in keyof T]: T[P] extends U ? P : never }[keyof T]

interface ICustomConfig extends AxiosRequestConfig {
	messages?: IErrorMessage[]
}

/* GET */

type GetUrls = {
	[Q in FilteredKeys<PathsDictionary, { get: any }>]: PathsDictionary[Q]
}

const cancelGetTokens = {} as { [key: string]: CancelTokenSource }

export const getReq = async <T extends keyof GetUrls>(
	url: T,
	params: Parameters<GetUrls[T]['get']>[0] = undefined,
	customConfig: ICustomConfig = {},
	notificationType: NOTIFICATION_TYPE | false = NOTIFICATION_TYPE.NOTIFICATION,
	showLoading = false,
	allowCancelToken = false
): Promise<ReturnType<GetUrls[T]['get']>> => {
	const { fullfilURL, queryParams } = fullFillURL(url, params)
	let hide
	let token = {}
	if (allowCancelToken) {
		if (typeof cancelGetTokens[fullfilURL] !== typeof undefined) {
			cancelGetTokens[fullfilURL].cancel('Operation canceled due to new request.')
		}
		cancelGetTokens[fullfilURL] = axios.CancelToken.source()
		token = { cancelToken: cancelGetTokens[fullfilURL].token }
	}
	if (showLoading) {
		hide = antMessage.loading(`${i18next.t('loc:general|Loading data')}...`, 0)
	}
	const config: AxiosRequestConfig = {
		paramsSerializer: qs.stringify,
		...customConfig,
		...token,
		headers: {
			...buildHeaders(),
			...get(customConfig, 'headers', {})
		}
	}
	if (queryParams) {
		config.params = queryParams
	}
	try {
		const response = await (axios.get(fullfilURL, config) as Promise<ReturnType<GetUrls[T]['get']>>)
		if (notificationType) {
			if (customConfig && customConfig.messages) {
				showNotifications(customConfig.messages, notificationType)
			} else if (has(response, 'data.messages')) {
				showNotifications(get(response, 'data.messages'), notificationType)
			}
		}
		if (hide) hide()
		return response
	} catch (error: any) {
		if (hiddenRequestError(error?.request?.responseURL) && !axios.isCancel(error)) {
			showErrorNotifications(error)
		}
		if (hide) hide()
		return Promise.reject(error)
	}
}

/* POST */

type PostUrls = {
	[Q in FilteredKeys<PathsDictionary, { post: any }>]: PathsDictionary[Q]
}

const cancelPostTokens = {} as { [key: string]: CancelTokenSource }

export const postReq = async <T extends keyof PostUrls>(
	url: T,
	params: Parameters<PostUrls[T]['post']>[0] = undefined,
	reqBody: Parameters<PostUrls[T]['post']>[1] = undefined,
	customConfig: ICustomConfig = {},
	notificationType: NOTIFICATION_TYPE | false = NOTIFICATION_TYPE.NOTIFICATION,
	showLoading = false,
	allowCancelToken = false
): Promise<ReturnType<PostUrls[T]['post']>> => {
	const { fullfilURL, queryParams } = fullFillURL(url, params)
	let hide
	let token = {}
	if (allowCancelToken) {
		if (typeof cancelPostTokens[fullfilURL] !== typeof undefined) {
			cancelPostTokens[fullfilURL].cancel('Operation canceled due to new request.')
		}
		cancelPostTokens[fullfilURL] = axios.CancelToken.source()
		token = { cancelToken: cancelPostTokens[fullfilURL].token }
	}
	if (showLoading) {
		hide = antMessage.loading(`${i18next.t('loc:general|Operation is being performed')}...`, 0)
	}
	const config = {
		...customConfig,
		...token,
		headers: {
			...buildHeaders(),
			...get(customConfig, 'headers', {})
		}
	}
	if (queryParams) {
		config.params = queryParams
	}
	try {
		const response = await axios.post(fullfilURL, reqBody, config)
		if (notificationType) {
			if (customConfig && customConfig.messages) {
				showNotifications(customConfig.messages, notificationType)
			} else if (has(response, 'data.messages')) {
				showNotifications(get(response, 'data.messages'), notificationType)
			}
		}
		if (hide) hide()
		return response as any
	} catch (error) {
		if (!axios.isCancel(error)) {
			showErrorNotifications(error)
		}
		if (hide) hide()
		return Promise.reject(error)
	}
}

/* PUT */

type PutUrls = {
	[Q in FilteredKeys<PathsDictionary, { put: any }>]: PathsDictionary[Q]
}

const cancelPutTokens = {} as { [key: string]: CancelTokenSource }

export const putReq = async <T extends keyof PutUrls>(
	url: T,
	params: Parameters<PutUrls[T]['put']>[0] = undefined,
	reqBody: Parameters<PutUrls[T]['put']>[1] = undefined,
	customConfig: ICustomConfig = {},
	notificationType: NOTIFICATION_TYPE | false = NOTIFICATION_TYPE.NOTIFICATION,
	showLoading = false,
	allowCancelToken = false
): Promise<ReturnType<PutUrls[T]['put']>> => {
	const { fullfilURL, queryParams } = fullFillURL(url, params)
	let hide
	let token = {}
	if (allowCancelToken) {
		if (typeof cancelPutTokens[fullfilURL] !== typeof undefined) {
			cancelPutTokens[fullfilURL].cancel('Operation canceled due to new request. ')
		}
		cancelPutTokens[fullfilURL] = axios.CancelToken.source()
		token = { cancelToken: cancelPutTokens[fullfilURL].token }
	}
	if (showLoading) {
		hide = antMessage.loading(`${i18next.t('loc:general|Operation is being performed')}...`, 0)
	}
	const config = {
		...customConfig,
		...token,
		headers: {
			...buildHeaders(),
			...get(customConfig, 'headers', {})
		}
	}
	if (queryParams) {
		config.params = queryParams
	}
	try {
		const response = await (axios.put(fullfilURL, reqBody, config) as Promise<ReturnType<PutUrls[T]['put']>>)
		if (notificationType) {
			if (customConfig && customConfig.messages) {
				showNotifications(customConfig.messages, notificationType)
			} else if (has(response, 'data.messages')) {
				showNotifications(get(response, 'data.messages'), notificationType)
			}
		}
		if (hide) hide()
		return response
	} catch (error) {
		if (!axios.isCancel(error)) {
			showErrorNotifications(error)
		}
		if (hide) hide()
		return Promise.reject(error)
	}
}

/* PATCH */

type PatchUrls = {
	[Q in FilteredKeys<PathsDictionary, { patch: any }>]: PathsDictionary[Q]
}

const cancelPatchTokens = {} as { [key: string]: CancelTokenSource }

export const patchReq = async <T extends keyof PatchUrls>(
	url: T,
	params: Parameters<PatchUrls[T]['patch']>[0] = undefined,
	reqBody: Parameters<PatchUrls[T]['patch']>[1] = undefined,
	customConfig: ICustomConfig = {},
	notificationType: NOTIFICATION_TYPE | false = NOTIFICATION_TYPE.NOTIFICATION,
	showLoading = false,
	allowCancelToken = false
): Promise<ReturnType<PatchUrls[T]['patch']>> => {
	const { fullfilURL, queryParams } = fullFillURL(url, params)
	let hide
	let token = {}
	if (allowCancelToken) {
		if (typeof cancelPatchTokens[fullfilURL] !== typeof undefined) {
			cancelPatchTokens[fullfilURL].cancel('Operation canceled due to new request.')
		}
		cancelPatchTokens[fullfilURL] = axios.CancelToken.source()
		token = { cancelToken: cancelPatchTokens[fullfilURL].token }
	}
	if (showLoading) {
		hide = antMessage.loading(`${i18next.t('loc:general|Operation is being performed')}...`, 0)
	}
	const config = {
		...customConfig,
		...token,
		headers: {
			...buildHeaders(),
			...get(customConfig, 'headers', {})
		}
	}
	if (queryParams) {
		config.params = queryParams
	}
	try {
		const response = await (axios.patch(fullfilURL, reqBody, config) as Promise<ReturnType<PatchUrls[T]['patch']>>)
		if (notificationType && customConfig && customConfig.messages) {
			showNotifications(customConfig.messages, notificationType)
		} else if (notificationType && has(response, 'data.messages')) {
			showNotifications(get(response, 'data.messages'), notificationType)
		}
		if (hide) hide()
		return response
	} catch (error) {
		if (!axios.isCancel(error)) {
			showErrorNotifications(error)
		}
		if (hide) hide()
		return Promise.reject(error)
	}
}

/* DELETE */

type DeleteUrls = {
	[Q in FilteredKeys<PathsDictionary, { delete: any }>]: PathsDictionary[Q]
}

export const deleteReq = async <T extends keyof DeleteUrls>(
	url: T,
	params: Parameters<DeleteUrls[T]['delete']>[0] = undefined,
	customConfig: ICustomConfig = {},
	notificationType: NOTIFICATION_TYPE | false = NOTIFICATION_TYPE.NOTIFICATION,
	showLoading = false
): Promise<ReturnType<DeleteUrls[T]['delete']>> => {
	const { fullfilURL, queryParams } = fullFillURL(url, params)
	let hide
	if (showLoading) {
		hide = antMessage.loading(`${i18next.t('loc:general|Operation is being performed')}...`, 0)
	}
	const config = {
		...customConfig,
		headers: {
			...buildHeaders(),
			...get(customConfig, 'headers', {})
		}
	}
	if (queryParams) {
		config.params = queryParams
	}
	try {
		const response = await (axios.delete(fullfilURL, config) as Promise<ReturnType<DeleteUrls[T]['delete']>>)
		if (notificationType && customConfig && customConfig.messages) {
			showNotifications(customConfig.messages, notificationType)
		} else if (notificationType && has(response, 'data.messages')) {
			showNotifications(get(response, 'data.messages'), notificationType)
		}
		if (hide) hide()
		return response
	} catch (error) {
		if (!axios.isCancel(error)) {
			showErrorNotifications(error)
		}
		if (hide) hide()
		return Promise.reject(error)
	}
}
