import { DatePicker, DatePickerProps } from 'antd'
import cx from 'classnames'
import dayjs from 'dayjs'
import { FC, memo } from 'react'
import { WrappedFieldInputProps, WrappedFieldMetaProps } from 'redux-form'
import { ReactComponent as ErrorIcon } from '../../assets/icons/error.svg'
import { formFieldID } from '../../utils/helpers'

type IDatePickerField = DatePickerProps & {
	label?: string
	input: Partial<WrappedFieldInputProps>
	meta: Partial<WrappedFieldMetaProps>
	inputClassName?: string
}

const DatePickerField: FC<IDatePickerField> = ({ label, placeholder, disabled, className, style, input, meta, inputClassName, ...props }) => {
	const { name = 'field', value = undefined, onChange = undefined } = input
	const { form = 'form', error = undefined, touched = false } = meta

	const id = formFieldID(form, name)

	return (
		<div className={cx('form-field', className)} style={style}>
			{label && (
				<label htmlFor={id} className={'form-field-label'}>
					{label}
				</label>
			)}
			<div className={'form-field-item'}>
				<DatePicker
					id={id}
					placeholder={placeholder}
					disabled={disabled}
					value={value ? (dayjs(value) as any) : undefined}
					onChange={onChange}
					className={cx(inputClassName, { error: error && touched })}
					format={'DD.MM.YYYY'}
					allowClear={false}
					{...props}
				/>
				<span className={'form-field-item-suffix'}>{error && touched && <ErrorIcon />}</span>
			</div>
			{error && touched && <span className={'form-field-message'}>{error}</span>}
		</div>
	)
}

export default memo(DatePickerField)
