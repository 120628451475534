/* eslint-disable react/jsx-no-useless-fragment */

import { Button, Spin } from 'antd'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useMediaQuery } from 'react-responsive'
import noWithdrawalsImage from '../../../assets/images/no-withdrawals.png'
import pendingWithdrawalImage from '../../../assets/images/pending-withdrawal.png'
import { RootState } from '../../../redux'
import { IEmployeesLoadDetailPayload } from '../../../redux/employees/employeesActions'
import { WITHDRAWAL_STATE } from '../../../utils/enums'

type IWithdrawalsDashboardButton = {}

const WithdrawalsDashboardButton: FC<IWithdrawalsDashboardButton> = () => {
	const { t } = useTranslation()
	const isMobile = useMediaQuery({ maxWidth: 991 })

	const employee: IEmployeesLoadDetailPayload = useSelector((state: RootState) => state.employees.detail)

	const handleCreateWithdrawalDrawerOpen = () => {
		const openButton: HTMLButtonElement | null = document.querySelector('#create-withdrawal-drawer-open-button')
		openButton?.click()
	}

	if (!employee.record) return null

	return employee.isLoading ? (
		<div className={'h-80 flex justify-center items-center'}>
			<Spin size={'large'} />
		</div>
	) : (
		<>
			{isMobile ? (
				<Button
					type={'primary'}
					shape={'round'}
					block={true}
					className={'shadow-md mb-6'}
					disabled={employee.record.withdrawalState === WITHDRAWAL_STATE.PENDING}
					onClick={handleCreateWithdrawalDrawerOpen}
				>
					{t('loc:employee|Request a Withdrawal')}
				</Button>
			) : (
				<div className={'flex flex-col items-center text-center gap-2 bg-white p-10 rounded-md'}>
					{employee.record.withdrawalState === WITHDRAWAL_STATE.PENDING ? (
						<>
							<img src={pendingWithdrawalImage} alt={''} width={'230'} />
							<span className={'text-40 font-600'}>{t('loc:employee|Pending request')}</span>
							<p className={'text-17 font-400'}>
								<span className={'inline lg:block'}>{t('loc:employee|A withdrawal request has been sent')}, </span>
								<span className={'inline lg:block'}>{t('loc:employee|we will let you know once it’s approved')}.</span>
							</p>
						</>
					) : (
						<>
							<img src={noWithdrawalsImage} alt={''} width={'230'} />
							<span className={'text-40 font-600'}>{`${t('loc:general|WageNow')}!`}</span>
							<p className={'text-22 font-700 opacity-60%'}>{t('loc:employee|Get your hard earned money without waiting')}.</p>
						</>
					)}
					<Button
						type={'primary'}
						shape={'round'}
						className={'shadow-md mt-3'}
						disabled={employee.record.withdrawalState === WITHDRAWAL_STATE.PENDING}
						onClick={handleCreateWithdrawalDrawerOpen}
					>
						{t('loc:employee|Request a Withdrawal')}
					</Button>
				</div>
			)}
		</>
	)
}

export default WithdrawalsDashboardButton
