/* eslint-disable object-shorthand */

import { ICreateAdminFormValues } from '../../layouts/admin/CreateAdminForm'
import { Paths } from '../../types/api'
import { USER_ROLE } from '../../utils/enums'
import { mapUserRoleEnumToID } from '../../utils/helpers'

// eslint-disable-next-line import/prefer-default-export
export const mapAdminsCreateRequest = (payload: ICreateAdminFormValues): Paths.PostApiAdminUsers.RequestBody => {
	const { firstName, lastName, email } = payload

	return {
		roleID: mapUserRoleEnumToID(USER_ROLE.ADMIN),
		firstName: firstName,
		lastName: lastName,
		email: email
	}
}
