/* eslint-disable object-shorthand */

import { map } from 'lodash'
import { Paths } from '../../types/api'
import { ICompaniesLoadListPayload } from './companiesActions'

// eslint-disable-next-line import/prefer-default-export
export const mapCompaniesLoadListResponse = (
	payload: Paths.GetApiAdminCompanies.Responses.$200
): Omit<ICompaniesLoadListPayload, 'isLoading' | 'isFailure'> => {
	const { companies, pagination } = payload

	return {
		records: [...map(companies, ({ id, name }) => ({ id: id, name: name }))],
		pagination: pagination
	}
}
