export enum USER_AUTH_RESET_PASSWORD {
	START = 'USER_AUTH_RESET_PASSWORD_START',
	DONE = 'USER_AUTH_RESET_PASSWORD_DONE',
	FAIL = 'USER_AUTH_RESET_PASSWORD_FAIL'
}

export enum USER_AUTH_LOGIN {
	START = 'USER_AUTH_LOGIN_START',
	DONE = 'USER_AUTH_LOGIN_DONE',
	FAIL = 'USER_AUTH_LOGIN_FAIL'
}

export enum USER_AUTH_LOGOUT {
	START = 'USER_AUTH_LOGOUT_START',
	DONE = 'USER_AUTH_LOGOUT_DONE',
	FAIL = 'USER_AUTH_LOGOUT_FAIL'
}

export enum USER_AUTH_FORGOT_PASSWORD {
	START = 'USER_AUTH_FORGOT_PASSWORD_START',
	DONE = 'USER_AUTH_FORGOT_PASSWORD_DONE',
	FAIL = 'USER_AUTH_FORGOT_PASSWORD_FAIL'
}

export enum USER_AUTH_REFRESH_TOKEN {
	START = 'USER_AUTH_REFRESH_TOKEN_START',
	DONE = 'USER_AUTH_REFRESH_TOKEN_DONE',
	FAIL = 'USER_AUTH_REFRESH_TOKEN_FAIL'
}

export enum USER_AUTH_DEACTIVATE {
	START = 'USER_AUTH_DEACTIVATE_START',
	DONE = 'USER_AUTH_DEACTIVATE_DONE',
	FAIL = 'USER_AUTH_DEACTIVATE_FAIL'
}

export enum USER_AUTH_RESEND_INVITATION {
	START = 'USER_AUTH_RESEND_INVITATION_START',
	DONE = 'USER_AUTH_RESEND_INVITATION_DONE',
	FAIL = 'USER_AUTH_RESEND_INVITATION_FAIL'
}
