/* eslint-disable object-shorthand */

import dayjs from 'dayjs'
import { isNil, map, omitBy } from 'lodash'
import { ICreateEmployeeFormValues } from '../../layouts/admin/CreateEmployeeForm'
import { IEmployeeAccountInfoFormValues } from '../../pages/employees/detail/EmployeeAccountInfoForm'
import { IEmployeesListFilterValues } from '../../pages/employees/list/EmployeesListFilter'
import { IEmployeeWithdrawalInfoFormValues } from '../../pages/employees/detail/EmployeeWithdrawalInfoForm'
import { Paths } from '../../types/api'
import { IEmployee } from '../../types/interfaces'
import { USER_ROLE } from '../../utils/enums'
import { mapOrderByToParams, mapUserRoleEnumToID, mapUserRoleIDToEnum, setAccountState, setDate, setWithdrawalState } from '../../utils/helpers'
import { IEmployeesLoadDetailPayload, IEmployeesLoadListPayload } from './employeesActions'

export const mapEmployeesLoadListRequest = (filter: IEmployeesListFilterValues): Paths.GetApiAdminUsers.QueryParameters => {
	const { page, limit, search, company, order } = filter

	return omitBy(
		{
			page: page,
			limit: limit as Paths.GetApiAdminUsers.Parameters.Limit,
			search: search,
			companyID: company,
			orderBy: mapOrderByToParams(order).orderBy,
			orderDirection: mapOrderByToParams(order).orderDirection
		},
		isNil
	) as Paths.GetApiAdminUsers.QueryParameters
}

export const mapEmployeesLoadListResponse = (payload: Paths.GetApiAdminUsers.Responses.$200): Omit<IEmployeesLoadListPayload, 'isLoading' | 'isFailure'> => {
	const { employees, pagination } = payload

	return {
		records: map(
			employees,
			({
				userID,
				role: { roleID },
				email,
				firstName,
				lastName,
				company,
				availableEarnings,
				startOfPeriod,
				endOfPeriod,
				nextPayDate,
				userStatus,
				requestStatus
			}) => ({
				id: userID,
				role: mapUserRoleIDToEnum(roleID),
				email: email,
				firstName: firstName,
				lastName: lastName,
				company: company,
				availableEarnings: availableEarnings || 0,
				periodStartDate: setDate(startOfPeriod),
				periodEndDate: setDate(endOfPeriod),
				nextPayDate: setDate(nextPayDate),
				accountState: setAccountState(userStatus),
				withdrawalState: setWithdrawalState(requestStatus)
			})
		),
		pagination: pagination
	}
}

export const mapEmployeesLoadDetailResponse = (
	payload: Paths.GetApiV1UsersUserId.Responses.$200
): Omit<IEmployeesLoadDetailPayload, 'isLoading' | 'isFailure'> => {
	const {
		employee: {
			userID,
			role: { roleID },
			email,
			firstName,
			lastName,
			company,
			bankAccountNumber,
			availableEarnings,
			startOfPeriod,
			endOfPeriod,
			nextPayDate,
			userStatus,
			confirmedAt,
			currentWithdrawal,
			userDashboard
		}
	} = payload

	return {
		record: {
			id: userID,
			role: mapUserRoleIDToEnum(roleID),
			email: email,
			firstName: firstName,
			lastName: lastName,
			company: company || null,
			bankAccountNumber: bankAccountNumber || undefined,
			availableEarnings: availableEarnings || 0,
			periodStartDate: setDate(startOfPeriod),
			periodEndDate: setDate(endOfPeriod),
			nextPayDate: setDate(nextPayDate),
			accountState: setAccountState(userStatus),
			activationDate: setDate(confirmedAt),
			withdrawalID: currentWithdrawal?.withdrawalID,
			withdrawalState: setWithdrawalState(currentWithdrawal?.status),
			withdrawalAmount: currentWithdrawal?.amount,
			userDashboard: userDashboard
		}
	}
}

export const mapEmployeesCreateRequest = (payload: ICreateEmployeeFormValues): Paths.PostApiAdminUsers.RequestBody => {
	const { firstName, lastName, email, companyID, bankAccountNumber } = payload

	return {
		roleID: mapUserRoleEnumToID(USER_ROLE.EMPLOYEE),
		firstName: firstName,
		lastName: lastName,
		email: email,
		companyID: companyID,
		bankAccountNumber: bankAccountNumber
	}
}

export const mapEmployeesUpdateRequest = (
	payload: IEmployeeAccountInfoFormValues & IEmployeeWithdrawalInfoFormValues & IEmployee
): Paths.PatchApiAdminUsersUserId.RequestBody => {
	const { firstName, lastName, email, company, companyID, bankAccountNumber, availableEarnings, periodStartDate, periodEndDate, nextPayDate } = payload

	return {
		roleID: mapUserRoleEnumToID(USER_ROLE.EMPLOYEE),
		firstName: firstName,
		lastName: lastName,
		email: email,
		companyID: companyID || company?.id,
		bankAccountNumber: bankAccountNumber,
		availableEarnings: availableEarnings || 0,
		startOfPeriod: periodStartDate && dayjs(periodStartDate).format(),
		endOfPeriod: periodEndDate && dayjs(periodEndDate).format(),
		nextPayDate: nextPayDate && dayjs(nextPayDate).format()
	}
}
