import { Button, Form } from 'antd'
import { FC, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Field, InjectedFormProps, reduxForm } from 'redux-form'
import checkMarkImage from '../../assets/images/check-mark.png'
import InputField from '../../atoms/form/InputField'
import SelectField from '../../atoms/form/SelectField'
import { RootState } from '../../redux'
import { companiesLoadList, ICompaniesLoadListPayload } from '../../redux/companies/companiesActions'
import { FORM } from '../../utils/enums'
import { getFilterCompanyOptions } from '../../utils/helpers'
import validateCreateEmployeeForm from './validateCreateEmployeeForm'

interface ICreateEmployeeForm {
	submitSuccess: boolean
	handleModalClose: () => void
}

export interface ICreateEmployeeFormValues {
	firstName: string
	lastName: string
	email: string
	companyID: number
	bankAccountNumber: string
}

const CreateEmployeeForm: FC<InjectedFormProps<ICreateEmployeeFormValues, ICreateEmployeeForm> & ICreateEmployeeForm> = ({
	handleSubmit,
	submitting,
	submitSuccess,
	handleModalClose
}) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()

	const companies: ICompaniesLoadListPayload = useSelector((state: RootState) => state.companies.list)

	useEffect(() => {
		dispatch(companiesLoadList())
	}, [dispatch])

	return submitSuccess ? (
		<>
			<img src={checkMarkImage} alt={''} className={'self-center mb-6'} style={{ width: '100px' }} />
			<h4 className={'text-center text-28 font-700 mb-2'}>{t('loc:admin|Account Created')}</h4>
			<p className={'text-center text-17 font-400 mb-8'}>{`${t('loc:admin|Invitation email was sent')}.`}</p>
			<Button type={'primary'} shape={'round'} block={true} onClick={handleModalClose}>
				{t('loc:general|Close')}
			</Button>
		</>
	) : (
		<Form noValidate={true} onSubmitCapture={handleSubmit}>
			<h4 className={'text-28 font-700 mb-6'}>{t('loc:admin|Add New Employee')}</h4>
			<Field name={'firstName'} component={InputField} placeholder={t('loc:admin|Name')} className={'mb-4'} />
			<Field name={'lastName'} component={InputField} placeholder={t('loc:admin|Surname')} className={'mb-4'} />
			<Field name={'email'} component={InputField} placeholder={t('loc:general|Email')} className={'mb-4'} />
			<Field
				name={'companyID'}
				component={SelectField}
				placeholder={t('loc:admin|Company')}
				options={getFilterCompanyOptions(companies.records).slice(1)}
				loading={companies.isLoading}
				disabled={!companies.isLoading && companies.isFailure}
				className={'mb-4'}
			/>
			<Field name={'bankAccountNumber'} component={InputField} placeholder={t('loc:admin|Bank Account Number (IBAN)')} className={'mb-10'} />
			<Button type={'primary'} shape={'round'} block={true} htmlType={'submit'} loading={submitting}>
				{t('loc:admin|Create Account')}
			</Button>
		</Form>
	)
}

export default reduxForm<ICreateEmployeeFormValues, ICreateEmployeeForm>({
	form: FORM.EMPLOYEE_CREATE,
	validate: validateCreateEmployeeForm,
	touchOnChange: true,
	destroyOnUnmount: true,
	forceUnregisterOnUnmount: true
})(CreateEmployeeForm)
