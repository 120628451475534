import { Button, Result } from 'antd'
import { FC, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import { APP_LANGUAGES } from '../utils/enums'

const OfflinePage: FC<{ children?: React.ReactNode }> = ({ children }) => {
	const { t, i18n } = useTranslation()

	// fix hardcoded language for entire app
	useEffect(() => {
		i18n.on('languageChanged', () => {
			document.documentElement.setAttribute('lang', APP_LANGUAGES.SK)
			dayjs.locale(APP_LANGUAGES.SK)
		})
	}, [])

	// useEffect(() => {
	// 	// TODO: rozbija a vrati blank screen + preco tu je takto natvrdo nastavenie jazyka na SK
	// 	i18n.changeLanguage(APP_LANGUAGES.SK)
	// 	// dayjs.locale('sk')
	// }, [])

	return (
		// eslint-disable-next-line react/jsx-no-useless-fragment
		<>
			{!navigator.onLine ? (
				<div className={'h-100% flex justify-center items-center m-4'}>
					<Result
						title={'Offline'}
						subTitle={t('loc:general:Offline description')}
						extra={
							<Button type={'primary'} shape={'round'} onClick={() => window.location.reload()}>
								{t('loc:general:Refresh')}
							</Button>
						}
					/>
				</div>
			) : (
				children
			)}
		</>
	)
}

export default OfflinePage
