import i18next from 'i18next'
import { FormErrors } from 'redux-form'
import { IResetPasswordFormValues } from './ResetPasswordForm'

export default (values: IResetPasswordFormValues) => {
	const errors: FormErrors<IResetPasswordFormValues> = {}

	if (!values.password) {
		errors.password = i18next.t('loc:general|This field is required')
	} else if (values.password.length > 255) {
		errors.password = i18next.t('loc:general|Maximum number of characters is {{max}}', { max: 255 })
	} else if (values.password.length < 8) {
		errors.password = i18next.t('loc:general|Password must be at least {{min}} characters long', { min: 8 })
	} else if (!/[a-zA-Z]/.test(values.password)) {
		errors.password = i18next.t('loc:general|Password must contain at least 1 letter')
	} else if (!/[0-9]/.test(values.password)) {
		errors.password = i18next.t('loc:general|Password must contain at least 1 number')
	}

	if (!values.confirmPassword) {
		errors.confirmPassword = i18next.t('loc:general|This field is required')
	} else if (values.confirmPassword.length > 255) {
		errors.confirmPassword = i18next.t('loc:general|Maximum number of characters is {{max}}', { max: 255 })
	} else if (values.confirmPassword !== values.password) {
		errors.confirmPassword = i18next.t('loc:general|Passwords must match')
	}

	if (!values.consent) {
		errors.consent = i18next.t('loc:general|This field is required')
	}

	return errors
}
