import { FC, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useIdleTimer } from 'react-idle-timer'
import { useDispatch, useSelector } from 'react-redux'
import { Navigate, Route, Routes as RoutesWrapper, unstable_HistoryRouter as HistoryRouter } from 'react-router-dom'
import { RootState } from '../redux'
import { IUserAuthPayload, userAuthRefreshToken } from '../redux/userAuth/userAuthActions'
import { getRefreshToken, hasAccessToken, hasRefreshToken } from '../utils/auth'
import { REFRESH_PAGE_INTERVAL, REFRESH_TOKEN_INTERVAL, USER_ROLE } from '../utils/enums'
import history from '../utils/history'

// routes
import AdminRoutes from './AdminRoutes'
import AuthRoutes from './AuthRoutes'
import EmployeeRoutes from './EmployeeRoutes'
import PublicRoutes from './PublicRoutes'

// pages
import ForgotPasswordPage from '../pages/auth/ForgotPasswordPage'
import LoginPage from '../pages/auth/LoginPage'
import ResetPasswordPage from '../pages/auth/ResetPasswordPage'
import EmployeeDetail from '../pages/employees/EmployeeDetail'
import EmployeesList from '../pages/employees/EmployeesList'
import NotFoundPage from '../pages/NotFoundPage'
import WelcomeFormPage from '../pages/welcomePage/WelcomeFormPage'
import WithdrawalDetail from '../pages/withdrawals/WithdrawalDetail'
import WithdrawalsDashboard from '../pages/withdrawals/WithdrawalsDashboard'
import WithdrawalsList from '../pages/withdrawals/WithdrawalsList'

const Routes: FC = () => {
	const { t } = useTranslation()
	const dispatch = useDispatch()

	const userAuth: IUserAuthPayload = useSelector((state: RootState) => state.userAuth)

	useIdleTimer({
		element: document,
		timeout: REFRESH_PAGE_INTERVAL,
		debounce: 250,
		onIdle: () => document.location.reload()
	})

	useEffect(() => {
		const refreshToken = () => {
			if (hasAccessToken() && hasRefreshToken()) {
				dispatch(userAuthRefreshToken(getRefreshToken() as string))
			}
		}
		const refreshTokenInterval = setInterval(refreshToken, REFRESH_TOKEN_INTERVAL)
		return () => clearInterval(refreshTokenInterval)
	}, [dispatch])

	return (
		<HistoryRouter history={history}>
			<RoutesWrapper>
				<Route element={<PublicRoutes hideTitle />}>
					<Route path={t('paths:welcome-page')} element={<WelcomeFormPage />} />
				</Route>
				<Route element={<AuthRoutes />}>
					<Route path={t('paths:login')} element={<LoginPage />} />
					<Route path={t('paths:forgot-password')} element={<ForgotPasswordPage />} />
					<Route path={t('paths:reset-password')} element={<ResetPasswordPage />} />
					<Route path={t('paths:admin/login')} element={<LoginPage />} />
					<Route path={t('paths:admin/forgot-password')} element={<ForgotPasswordPage />} />
					<Route path={t('paths:admin/reset-password')} element={<ResetPasswordPage />} />
					<Route path={'*'} element={<Navigate to={t('paths:login')} replace={true} />} />
				</Route>
				{userAuth.user?.role === USER_ROLE.ADMIN && (
					<Route element={<AdminRoutes />}>
						<Route path={t('paths:employees')} element={<EmployeesList />} />
						<Route path={t('paths:employees/{{id}}', { id: ':id' })} element={<EmployeeDetail />} />
						<Route path={t('paths:withdrawals')} element={<WithdrawalsList />} />
						<Route path={t('paths:withdrawals/{{id}}', { id: ':id' })} element={<WithdrawalDetail />} />
						<Route path={'*'} element={<NotFoundPage />} />
					</Route>
				)}
				{userAuth.user?.role === USER_ROLE.EMPLOYEE && (
					<Route element={<EmployeeRoutes />}>
						<Route path={t('paths:withdrawals')} element={<WithdrawalsDashboard />} />
						<Route path={'*'} element={<NotFoundPage />} />
					</Route>
				)}
			</RoutesWrapper>
		</HistoryRouter>
	)
}

export default Routes
