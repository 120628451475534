import { Button } from 'antd'
import qs from 'qs'
import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import checkMarkImage from '../../assets/images/check-mark.png'
import BasicModal from '../../atoms/BasicModal'
import { userAuthResetPassword } from '../../redux/userAuth/userAuthActions'
import { isAdminRoute } from '../../utils/helpers'
import ResetPasswordForm, { IResetPasswordFormValues } from './reset-password/ResetPasswordForm'

const ResetPasswordPage: FC = () => {
	const { t } = useTranslation()
	const navigate = useNavigate()
	const dispatch = useDispatch()

	const [successModalVisible, setSuccessModalVisible] = useState(false)

	const { t: authToken } = qs.parse(document.location.search, { ignoreQueryPrefix: true })

	useEffect(() => {
		document.title = `${t('loc:general|Reset password')} - ${t('loc:general|WageNow')}`
	})

	useEffect(() => {
		if (process.env.NODE_ENV === 'production' && !authToken) {
			navigate(isAdminRoute ? t('paths:admin/login') : t('paths:login'))
		}
	}, [authToken, navigate, t])

	const handleResetPasswordFormSubmit = async (values: IResetPasswordFormValues) => {
		await dispatch(
			userAuthResetPassword(values, authToken as string, () => {
				setSuccessModalVisible(true)
			})
		)
	}

	const handleSuccessModalClose = () => {
		setSuccessModalVisible(false)
		setTimeout(() => navigate(isAdminRoute ? t('paths:admin/login') : t('paths:login')), 500)
	}

	return (
		<>
			<span className={'block text-28 font-700 mb-4'}>{t('loc:general|Create new password')}</span>
			<ResetPasswordForm onSubmit={handleResetPasswordFormSubmit} />

			<BasicModal visible={successModalVisible} onClose={handleSuccessModalClose} width={'30rem'}>
				<img src={checkMarkImage} alt={''} className={'self-center mb-6'} style={{ width: '100px' }} />
				<h4 className={'text-center text-28 font-700 mb-2'}>{t('loc:general|New password created')}</h4>
				<p className={'text-center text-17 font-400 mb-8'}>{`${t('loc:general|You can now log in')}.`}</p>
				<Button type={'primary'} shape={'round'} block={true} onClick={handleSuccessModalClose}>
					{t('loc:general|Continue')}
				</Button>
			</BasicModal>
		</>
	)
}

export default ResetPasswordPage
