import { ICompany, ILoadingAndFailure, IPaginationResponse } from '../../types/interfaces'
import { getReq } from '../../utils/requests'
import { ThunkResult } from '../index'
import { mapCompaniesLoadListResponse } from './companiesHelpers'
import { COMPANIES_LOAD_LIST } from './companiesTypes'

/* Load list */

export interface ICompaniesLoadListPayload extends ILoadingAndFailure {
	records?: ICompany[]
	pagination?: IPaginationResponse
}

interface ICompaniesLoadListAction {
	type: COMPANIES_LOAD_LIST
	payload: ICompaniesLoadListPayload
}

export const companiesLoadList = (): ThunkResult<void> => async (dispatch) => {
	try {
		dispatch({ type: COMPANIES_LOAD_LIST.START })
		const { data } = await getReq('/api/admin/companies/', { page: 1, limit: 100 })
		dispatch({ type: COMPANIES_LOAD_LIST.DONE, payload: mapCompaniesLoadListResponse(data) })
	} catch (error) {
		dispatch({ type: COMPANIES_LOAD_LIST.FAIL })
	}
}

/* Actions */

export type ICompaniesAction = ICompaniesLoadListAction
