import { Button, Form, Spin } from 'antd'
import { Dayjs } from 'dayjs'
import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Field, getFormValues, initialize, InjectedFormProps, reduxForm } from 'redux-form'
import checkMarkImage from '../../../assets/images/check-mark.png'
import exclamationMarkImage from '../../../assets/images/exclamation-mark.png'
import BasicModal from '../../../atoms/BasicModal'
import DatePickerField from '../../../atoms/form/DatePickerField'
import NumberInputField from '../../../atoms/form/NumberInputField'
import { RootState } from '../../../redux'
import { employeesLoadDetail, employeesUpdate, IEmployeesLoadDetailPayload } from '../../../redux/employees/employeesActions'
import { FORM } from '../../../utils/enums'
import validateEmployeeWithdrawalInfoForm from './validateEmployeeWithdrawalInfoForm'

type IEmployeeWithdrawalInfoForm = {}

export interface IEmployeeWithdrawalInfoFormValues {
	firstName: string
	lastName: string
	email: string
	availableEarnings?: number
	periodStartDate?: Dayjs
	periodEndDate?: Dayjs
	nextPayDate?: Dayjs
}

const EmployeeWithdrawalInfoForm: FC<InjectedFormProps<IEmployeeWithdrawalInfoFormValues, IEmployeeWithdrawalInfoForm> & IEmployeeWithdrawalInfoForm> = ({
	submitting,
	invalid,
	pristine
}) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()

	const employee: IEmployeesLoadDetailPayload = useSelector((state: RootState) => state.employees.detail)
	const withdrawalDetail = useSelector((state: RootState) => state.withdrawals.detail)

	const formValues = useSelector((state: RootState) => getFormValues(FORM.EMPLOYEE_WITHDRAWAL_INFO)(state)) as IEmployeeWithdrawalInfoFormValues

	const [editConfirmModalVisible, setEditConfirmModalVisible] = useState<boolean>(false)
	const [editSuccessModalVisible, setEditeditSuccessModalVisible] = useState<boolean>(false)

	const handleFormSubmit = async () => {
		if (!employee.record) return
		await dispatch(
			employeesUpdate(
				{ ...employee.record, ...formValues },
				() => {
					setEditConfirmModalVisible(false)
					setEditeditSuccessModalVisible(true)
					if (!employee.record) return
					dispatch(employeesLoadDetail(employee.record.id))
				},
				false
			)
		)
	}

	useEffect(() => {
		if (!employee.record) return
		dispatch(
			initialize(FORM.EMPLOYEE_WITHDRAWAL_INFO, {
				firstName: employee.record.firstName,
				lastName: employee.record.lastName,
				email: employee.record.email,
				availableEarnings: employee.record.availableEarnings,
				periodStartDate: employee.record.periodStartDate,
				periodEndDate: employee.record.periodEndDate,
				nextPayDate: employee.record.nextPayDate
			})
		)
	}, [employee, dispatch])

	useEffect(() => {
		if (!employee.record) return
		dispatch(employeesLoadDetail(employee.record.id))
	}, [withdrawalDetail.record?.state])

	return (
		<>
			<Form className={'bg-purple-medium p-4 lg:p-6 rounded-md mb-6'}>
				<h2 className={'text-20 lg:text-22 font-700 mb-6'}>{t('loc:admin|Withdrawal Permissions')}</h2>
				<Spin spinning={employee.isLoading} size={'large'}>
					<Field
						name={'availableEarnings'}
						component={NumberInputField}
						precision={2}
						disabled={employee.isLoading}
						setZeroForNoValue={true}
						label={t('loc:admin|Available Earnings')}
						placeholder={t('loc:admin|Available Earnings')}
						inputClassName={'bg-white'}
						className={'mb-4'}
					/>
					<Field
						name={'periodStartDate'}
						component={DatePickerField}
						disabled={employee.isLoading}
						label={t('loc:admin|Current Period Start')}
						placeholder={t('loc:admin|Current Period Start')}
						showToday={false}
						inputClassName={'bg-white'}
						className={'mb-4'}
					/>
					<Field
						name={'periodEndDate'}
						component={DatePickerField}
						disabled={employee.isLoading}
						label={t('loc:admin|Current Period End')}
						placeholder={t('loc:admin|Current Period End')}
						showToday={false}
						inputClassName={'bg-white'}
						className={'mb-4'}
					/>
					<Field
						name={'nextPayDate'}
						component={DatePickerField}
						disabled={employee.isLoading}
						label={t('loc:general|Next Payday')}
						placeholder={t('loc:general|Next Payday')}
						showToday={false}
						inputClassName={'bg-white'}
						className={'mb-4'}
					/>
					<div className={'flex flex-col items-center gap-4 mx-9 mt-6'}>
						<Button
							htmlType={'submit'}
							type={'primary'}
							shape={'round'}
							disabled={invalid || pristine || employee.isLoading}
							loading={submitting}
							onClick={() => setEditConfirmModalVisible(true)}
						>
							{t('loc:general|Save')}
						</Button>
					</div>
				</Spin>
			</Form>

			<BasicModal visible={editConfirmModalVisible} onClose={() => setEditConfirmModalVisible(false)} width={'30rem'}>
				<img src={exclamationMarkImage} width={36} alt={''} className={'self-center mb-6'} />
				<p className={'text-center text-15 font-400 mb-6'}>
					{`${t('loc:admin|Are you sure you want to save changes for')} `}
					<br className={'hidden lg:inline'} />
					<b>{`${employee.record?.firstName} ${employee.record?.lastName}’s`}</b> {`${t('loc:admin|account')}?`}
				</p>
				<Button type={'primary'} shape={'round'} className={'mb-4'} onClick={handleFormSubmit}>
					{t('loc:admin|Yes, save changes')}
				</Button>
				<Button type={'text'} className={'mb-4'} onClick={() => setEditConfirmModalVisible(false)}>
					{t('loc:general|Cancel')}
				</Button>
			</BasicModal>

			<BasicModal visible={editSuccessModalVisible} onClose={() => setEditeditSuccessModalVisible(false)} width={'30rem'}>
				<img src={checkMarkImage} width={100} alt={''} className={'self-center mb-6'} />
				<h4 className={'text-center text-28 font-700 leading-sm mb-6'}>{t('loc:admin|Changes saved')}</h4>
				<Button type={'primary'} shape={'round'} onClick={() => setEditeditSuccessModalVisible(false)}>
					{t('loc:general|Close')}
				</Button>
			</BasicModal>
		</>
	)
}

export default reduxForm<IEmployeeWithdrawalInfoFormValues, IEmployeeWithdrawalInfoForm>({
	form: FORM.EMPLOYEE_WITHDRAWAL_INFO,
	validate: validateEmployeeWithdrawalInfoForm,
	touchOnChange: true,
	destroyOnUnmount: true,
	forceUnregisterOnUnmount: true
})(EmployeeWithdrawalInfoForm)
