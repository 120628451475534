import { Progress, Spin } from 'antd'
import dayjs from 'dayjs'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { ReactComponent as CalendarIcon } from '../../../assets/icons/calendar.svg'
import { RootState } from '../../../redux'
import { IEmployeesLoadDetailPayload } from '../../../redux/employees/employeesActions'
import { DASHBOARD_FIELDS, WITHDRAWAL_STATE } from '../../../utils/enums'
import { formatAsCurrency } from '../../../utils/helpers'

type IWithdrawalsDashboardOverview = {}

const WithdrawalsDashboardOverview: FC<IWithdrawalsDashboardOverview> = () => {
	const { t } = useTranslation()

	const employee: IEmployeesLoadDetailPayload = useSelector((state: RootState) => state.employees.detail)

	const today = dayjs().startOf('day')
	const periodStartDate = dayjs(employee.record?.periodStartDate).startOf('day')
	const periodEndDate = dayjs(employee.record?.periodEndDate).startOf('day')
	const nextPayDate = dayjs(employee.record?.nextPayDate).startOf('day')

	const daysInCurrentPeriod = periodEndDate.diff(periodStartDate, 'days')
	const daysBetweenTodayAndPeriodStartDate = today.diff(periodStartDate, 'days')
	const percentageProgressOfCurrentPeriod = Number(((daysBetweenTodayAndPeriodStartDate / daysInCurrentPeriod) * 100).toFixed(2))
	const daysBetweenTodayAndNextPayDate = today.diff(nextPayDate, 'days')

	const firstLineLabel = employee.record?.userDashboard?.find((item) => item.fieldName === DASHBOARD_FIELDS.TEXT_AREA_1)?.data
	const secondLineLabel = employee.record?.userDashboard?.find((item) => item.fieldName === DASHBOARD_FIELDS.TEXT_AREA_2)?.data
	const withdrawalDateLabel = employee.record?.userDashboard?.find((item) => item.fieldName === DASHBOARD_FIELDS.TEXT_AREA_3)?.data

	const calculateAvailableEarnings = () => {
		if (employee.record?.withdrawalAmount && employee.record?.withdrawalState === WITHDRAWAL_STATE.PENDING) {
			return (employee.record?.availableEarnings || 0) - employee.record.withdrawalAmount
		}
		return employee.record?.availableEarnings || 0
	}

	return employee.isLoading ? (
		<div className={'h-80 flex justify-center items-center'}>
			<Spin size={'large'} />
		</div>
	) : (
		<>
			{/* radsej to tu necham keby nahodou */}

			{/* {isMobile && employee.record?.withdrawalID && employee.record?.withdrawalState === WITHDRAWAL_STATE.PENDING && ( */}
			{/*	<div className={'flex justify-between items-center text-14 font-400 bg-white p-4 rounded-md shadow-lg mb-6'}> */}
			{/*		<span> {`${t('loc:employee|Withdrawal Request')} ${formatAsCurrency(employee.record.withdrawalAmount)}`}</span> */}
			{/*		<StateItem state={employee.record.withdrawalState} /> */}
			{/*	</div> */}
			{/* )} */}

			<div className={'flex flex-col items-center bg-purple-light py-6 px-6 xxl:px-16 rounded-md shadow-md mb-6 lg:mb-10'}>
				{employee.record?.periodStartDate && employee.record?.periodEndDate && (
					<span className={'text-12 font-400 text-gray-500 mb-3'}>{`${dayjs(employee.record.periodStartDate).format('D MMMM')} - ${dayjs(
						employee.record.periodEndDate
					).format('D MMMM')}`}</span>
				)}

				<span className={'text-17 font-400 mb-2'}>{t('loc:employee|Your available Earnings')}</span>
				<h2 className={'text-40 font-600 leading-none'}>{formatAsCurrency(calculateAvailableEarnings()) as string}</h2>

				{!!firstLineLabel && <span className={'text-14 font-400 mt-2'} dangerouslySetInnerHTML={{ __html: firstLineLabel }} />}

				{employee.record?.withdrawalAmount && employee.record?.withdrawalState === WITHDRAWAL_STATE.PENDING && (
					<h3
						className={
							'inline-flex items-center whitespace-nowrap text-16 font-600 mt-3 before:w-2 before:h-2 before:bg-warning before:rounded-full before:mr-3 before:flex-shrink-0'
						}
					>{`${formatAsCurrency(employee.record.withdrawalAmount)} ${t('loc:employee|pending')}`}</h3>
				)}

				{employee.record?.periodStartDate && employee.record?.periodEndDate && employee.record?.nextPayDate && (
					<>
						<Progress type={'line'} strokeWidth={14} showInfo={false} percent={percentageProgressOfCurrentPeriod} className={'my-3'} />

						{!!secondLineLabel && <span className={'text-12 font-400 mb-6 mt-1'} dangerouslySetInnerHTML={{ __html: secondLineLabel }} />}

						<div className={'w-100% flex justify-between items-center text-13 font-400 text-gray-500'}>
							<span className={'inline-flex items-center gap-2'}>
								<CalendarIcon className={'text-18'} />
								{withdrawalDateLabel
									? `
										${withdrawalDateLabel}:
										${dayjs(employee.record.nextPayDate).format('D MMMM')}
									`
									: `
										${daysBetweenTodayAndNextPayDate > 0 ? t('loc:employee|Last payday') : t('loc:general|Next Payday')}:
										${dayjs(employee.record.nextPayDate).format('D MMMM')}
									`}
							</span>
							{daysBetweenTodayAndNextPayDate > 0 ? (
								<span>
									{Math.abs(daysBetweenTodayAndNextPayDate) === 1
										? t('loc:employee|{{days}} day ago', { days: Math.abs(daysBetweenTodayAndNextPayDate) })
										: t('loc:employee|{{days}} days ago', { days: Math.abs(daysBetweenTodayAndNextPayDate) })}
								</span>
							) : (
								<span>
									{Math.abs(daysBetweenTodayAndNextPayDate) === 1 &&
										t('loc:employee|{{days}} day left', { days: Math.abs(daysBetweenTodayAndNextPayDate) })}
									{[2, 3, 4].includes(Math.abs(daysBetweenTodayAndNextPayDate)) &&
										t('loc:employee|{{days}} days left', { days: Math.abs(daysBetweenTodayAndNextPayDate) })}
									{Math.abs(daysBetweenTodayAndNextPayDate) >= 5 &&
										t('loc:employee|{{days}} dayss left', { days: Math.abs(daysBetweenTodayAndNextPayDate) })}
								</span>
							)}
						</div>
					</>
				)}
			</div>
		</>
	)
}

export default WithdrawalsDashboardOverview
