import dayjs from 'dayjs'
import i18next from 'i18next'
import { FormErrors } from 'redux-form'
import { IEmployeeWithdrawalInfoFormValues } from './EmployeeWithdrawalInfoForm'

export default (values: IEmployeeWithdrawalInfoFormValues) => {
	const errors: FormErrors<IEmployeeWithdrawalInfoFormValues> = {}

	if (values.availableEarnings && values.availableEarnings < 0) {
		errors.availableEarnings = i18next.t('loc:general|Amount cannot be negative')
	}

	if (!values.periodStartDate) {
		errors.periodStartDate = i18next.t('loc:general|This field is required')
	}

	if (!values.periodEndDate) {
		errors.periodEndDate = i18next.t('loc:general|This field is required')
	} else if (!dayjs(values.periodEndDate).isAfter(dayjs(values.periodStartDate))) {
		errors.periodEndDate = `${i18next.t('loc:admin|Date must be later then')} ${i18next.t('loc:admin|Current Period Start')}`
	}

	if (!values.nextPayDate) {
		errors.nextPayDate = i18next.t('loc:general|This field is required')
	} else if (!dayjs(values.nextPayDate).isAfter(dayjs(values.periodEndDate))) {
		errors.nextPayDate = `${i18next.t('loc:admin|Date must be later then')} ${i18next.t('loc:admin|Current Period End')}`
	}

	return errors
}
