import { Button, Form } from 'antd'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Field, InjectedFormProps, reduxForm } from 'redux-form'
import InputField from '../../../atoms/form/InputField'
import { FORM } from '../../../utils/enums'
import validateForgotPasswordForm from './validateForgotPasswordForm'

export interface IForgotPasswordFormValues {
	email: string
}

const ForgotPasswordForm: FC<InjectedFormProps<IForgotPasswordFormValues>> = ({ handleSubmit, submitting }) => {
	const { t } = useTranslation()

	return (
		<Form noValidate={true} onSubmitCapture={handleSubmit}>
			<Field name={'email'} component={InputField} placeholder={t('loc:general|Email')} required className={'mb-8'} />
			<Button type={'primary'} shape={'round'} block={true} htmlType={'submit'} loading={submitting}>
				{t('loc:general|Continue')}
			</Button>
		</Form>
	)
}

export default reduxForm<IForgotPasswordFormValues>({
	form: FORM.FORGOT_PASSWORD,
	validate: validateForgotPasswordForm,
	touchOnChange: true,
	destroyOnUnmount: true,
	forceUnregisterOnUnmount: true
})(ForgotPasswordForm)
