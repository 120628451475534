import { Button } from 'antd'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { ReactComponent as CrossIcon } from '../../assets/icons/cross.svg'
import questionMarkImage from '../../assets/images/question-mark.png'
import BasicModal from '../../atoms/BasicModal'

interface IHelpModal {
	visible: boolean
	setVisible: (value: boolean) => void
}

const HelpModal: FC<IHelpModal> = ({ visible, setVisible }) => {
	const { t } = useTranslation()

	return (
		<BasicModal visible={visible} onClose={() => setVisible(false)} width={'30rem'}>
			<img src={questionMarkImage} alt={''} className={'self-center mb-6'} style={{ width: '64px' }} />
			<h4 className={'text-center text-28 font-700 mb-6'}>{t('loc:employee|Help & Support')}</h4>
			<p className={'text-center text-15 font-400'}>
				{`${t('loc:employee|Please contact support at email address')}: `}
				<a href={`mailto:${t('loc:general|SUPPORT_EMAIL_ADDRESS')}`}>{t('loc:general|SUPPORT_EMAIL_ADDRESS')}</a>
			</p>
			<Button
				type={'text'}
				icon={<CrossIcon />}
				onClick={() => setVisible(false)}
				className={'absolute z-1 top-8 right-8 text-14 text-gray-500 hover:text-gray-900'}
			/>
		</BasicModal>
	)
}

export default HelpModal
