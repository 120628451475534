export enum EMPLOYEES_LOAD_LIST {
	START = 'EMPLOYEES_LOAD_LIST_START',
	DONE = 'EMPLOYEES_LOAD_LIST_DONE',
	FAIL = 'EMPLOYEES_LOAD_LIST_FAIL'
}

export enum EMPLOYEES_LOAD_DETAIL {
	START = 'EMPLOYEES_LOAD_DETAIL_START',
	DONE = 'EMPLOYEES_LOAD_DETAIL_DONE',
	FAIL = 'EMPLOYEES_LOAD_DETAIL_FAIL',
	RESET = 'EMPLOYEES_LOAD_DETAIL_RESET'
}

export enum EMPLOYEES_CREATE {
	START = 'EMPLOYEES_CREATE_START',
	DONE = 'EMPLOYEES_CREATE_DONE',
	FAIL = 'EMPLOYEES_CREATE_FAIL'
}

export enum EMPLOYEES_UPDATE {
	START = 'EMPLOYEES_UPDATE_START',
	DONE = 'EMPLOYEES_UPDATE_DONE',
	FAIL = 'EMPLOYEES_UPDATE_FAIL'
}
