export enum WITHDRAWALS_LOAD_LIST {
	START = 'WITHDRAWALS_LOAD_LIST_START',
	DONE = 'WITHDRAWALS_LOAD_LIST_DONE',
	FAIL = 'WITHDRAWALS_LOAD_LIST_FAIL',
	RESET = 'WITHDRAWALS_LOAD_LIST_RESET'
}

export enum WITHDRAWALS_LOAD_DETAIL {
	START = 'WITHDRAWALS_LOAD_DETAIL_START',
	DONE = 'WITHDRAWALS_LOAD_DETAIL_DONE',
	FAIL = 'WITHDRAWALS_LOAD_DETAIL_FAIL',
	RESET = 'WITHDRAWALS_LOAD_DETAIL_RESET'
}

export enum WITHDRAWALS_LOAD_LIST_BY_USER {
	START = 'WITHDRAWALS_LOAD_LIST_BY_USER_START',
	DONE = 'WITHDRAWALS_LOAD_LIST_BY_USER_DONE',
	FAIL = 'WITHDRAWALS_LOAD_LIST_BY_USER_FAIL',
	RESET = 'WITHDRAWALS_LOAD_LIST_BY_USER_RESET'
}

export enum WITHDRAWALS_CREATE {
	START = 'WITHDRAWALS_CREATE_START',
	DONE = 'WITHDRAWALS_CREATE_DONE',
	FAIL = 'WITHDRAWALS_CREATE_FAIL'
}

export enum WITHDRAWALS_UPDATE_STATUS {
	START = 'WITHDRAWALS_UPDATE_STATUS_START',
	DONE = 'WITHDRAWALS_UPDATE_STATUS_DONE',
	FAIL = 'WITHDRAWALS_UPDATE_STATUS_FAIL'
}
