import { Button, Form, Row, Col } from 'antd'
import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMediaQuery } from 'react-responsive'
import usePlacesAutocomplete, { getGeocode } from 'use-places-autocomplete'
import { Field, InjectedFormProps, change, reduxForm } from 'redux-form'
import { useDispatch, useSelector } from 'react-redux'
import { find, includes, map } from 'lodash'
import { RootState } from '../../../redux'
import SwitchField from '../../../atoms/form/SwitchField'
import InputField from '../../../atoms/form/InputField'
import { FORM } from '../../../utils/enums'
import validateWelcomeForm from './validateWelcomeForm'
import DatePickerField from '../../../atoms/form/DatePickerField'
import SelectField from '../../../atoms/form/SelectField'
import PhoneNumberField from '../../../atoms/form/PhoneNumberField'

type IWelcomeForm = {}

export interface IWelcomeFormValues {
	businessName?: string
	businessRegistrationNumber?: number
	firstName?: string
	lastName?: string
	email?: string
	bankAccountNumber?: string
	phonePrefix?: string
	phoneNumber?: string
	birthday?: string
	street?: string
	houseNumber?: string
	city?: string
	zipCode?: string
	consent?: boolean
	employeeIdentifier?: string
}

const WelcomeForm: FC<InjectedFormProps<IWelcomeFormValues, IWelcomeForm> & IWelcomeForm> = ({ submitting, handleSubmit }) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const fromValues = useSelector((state: RootState) => state.form[FORM.ONBOARDING]?.values)
	const [savedData, setSavedData] = useState<any>()

	const removeDiacritics = (str: string) => {
		return str?.normalize('NFD').replace(/[\u0300-\u036f]/g, '') ?? ''
	}

	const transformDataDiacritics = () => {
		return savedData.map((item: any) => ({
			...item,
			description: removeDiacritics(item.description)
		}))
	}

	const {
		ready,
		setValue,
		suggestions: { data },
		clearSuggestions
	} = usePlacesAutocomplete({
		debounce: 100,
		requestOptions: {
			// NOTE: Civilian adresses only
			// types: ['geocode', 'establishment'],
			// componentRestrictions: { country: 'us' },
		}
	})

	const { data: onboarding } = useSelector((state: RootState) => state.onboarding)
	const isMobile = useMediaQuery({ maxWidth: 991 })

	const allowedFields = onboarding?.onboardingForm.additionalFields

	const businessNameField = find(allowedFields, ['fieldName', 'businessName'])
	const businessRegistrationNumberField = find(allowedFields, ['fieldName', 'businessRegistrationNumber'])
	const firstNameField = find(allowedFields, ['fieldName', 'firstName'])
	const lastNameField = find(allowedFields, ['fieldName', 'lastName'])
	const emailField = find(allowedFields, ['fieldName', 'email'])
	const birthdayField = find(allowedFields, ['fieldName', 'birthday'])
	const phoneNumberField = find(allowedFields, ['fieldName', 'phoneNumber'])
	const bankAccountNumberField = find(allowedFields, ['fieldName', 'bankAccountNumber'])
	const streetField = find(allowedFields, ['fieldName', 'street'])
	const cityField = find(allowedFields, ['fieldName', 'city'])
	const zipCodeField = find(allowedFields, ['fieldName', 'zipCode'])
	const employeeIdentifierField = find(allowedFields, ['fieldName', 'employeeIdentifier'])
	const consentField = find(allowedFields, ['fieldName', 'consent'])

	const handleSearch = (searchValue: string) => {
		if (searchValue) {
			setValue(searchValue)
			dispatch(change(FORM.ONBOARDING, 'street', searchValue))
		}
		if (searchValue === '' && fromValues?.street?.length === 1) {
			dispatch(change(FORM.ONBOARDING, 'street', ''))
		}
	}

	const handleSelect = (select: any) => {
		const modifiedData = transformDataDiacritics()

		const selectValue = find(modifiedData, ['description', select])

		interface IAddress {
			long_name: string
			short_name: string
			types: string[]
		}
		getGeocode({ address: selectValue?.description }).then((results) => {
			const street = find(
				results[0]?.address_components,
				(component: IAddress) => includes(component?.types, 'route') || includes(component?.types, 'locality')
			)
			const street_number = find(results[0]?.address_components, (component: IAddress) => includes(component?.types, 'street_number'))
			const premise = find(results[0]?.address_components, (component: IAddress) => includes(component?.types, 'premise'))
			const subpremise = find(results[0]?.address_components, (component: IAddress) => includes(component?.types, 'subpremise'))
			const postalCode = find(results[0]?.address_components, (component: IAddress) => includes(component?.types, 'postal_code'))
			const city = find(
				results[0]?.address_components,
				(component: IAddress) => includes(component?.types, 'sublocality') || includes(component?.types, 'sublocality_level_1')
			)
			const district = find(
				results[0]?.address_components,
				(component: IAddress) => includes(component?.types, 'postal_town') || includes(component?.types, 'administrative_area_level_2')
			)
			if (postalCode) {
				dispatch(change(FORM.ONBOARDING, 'zipCode', postalCode?.long_name))
			}
			if (city) {
				if (city?.long_name === district?.long_name) {
					dispatch(change(FORM.ONBOARDING, 'city', city?.long_name))
				} else {
					dispatch(change(FORM.ONBOARDING, 'city', `${city?.long_name}, ${district?.long_name}`))
				}
			} else if (district) {
				dispatch(change(FORM.ONBOARDING, 'city', district?.long_name))
			} else {
				dispatch(change(FORM.ONBOARDING, 'city', street?.long_name))
			}
			if (premise) {
				if (subpremise) {
					dispatch(change(FORM.ONBOARDING, 'houseNumber', `${premise?.long_name}/${subpremise?.long_name}`))
				} else {
					dispatch(change(FORM.ONBOARDING, 'houseNumber', premise?.long_name))
				}
			}
			if (street) {
				dispatch(change(FORM.ONBOARDING, 'street', street?.long_name))
				if (street_number) {
					dispatch(change(FORM.ONBOARDING, 'street', `${street?.long_name} ${street_number?.long_name}`))
				}
			}
			clearSuggestions()
		})
	}

	useEffect(() => {
		if (data.length > 0) {
			setSavedData(data)
		}
	}, [data])

	useEffect(() => {
		dispatch(change(FORM.ONBOARDING, 'phonePrefix', '+421'))
	}, [])

	const submitDisabled = () => {
		const unfilled = []
		if (fromValues?.businessName === undefined && businessNameField?.isRequired) {
			unfilled.push(t('loc:general|Company name'))
		}
		if (fromValues?.businessRegistrationNumber === undefined && businessRegistrationNumberField?.isRequired) {
			unfilled.push(t('loc:general|Company id'))
		}
		if (fromValues?.firstName === undefined && firstNameField?.isRequired) {
			unfilled.push(t('loc:general|First name'))
		}
		if (fromValues?.lastName === undefined && lastNameField?.isRequired) {
			unfilled.push(t('loc:general|Last name'))
		}
		if (fromValues?.employeeIdentifier === undefined && employeeIdentifierField?.isRequired) {
			unfilled.push(t('loc:general|Employee identifier'))
		}
		if (fromValues?.email === undefined && emailField?.isRequired) {
			unfilled.push(t('loc:general|Email'))
		}
		if (fromValues?.birthday === undefined && birthdayField?.isRequired) {
			unfilled.push(t('loc:general|Birth day'))
		}
		if (fromValues?.phoneNumber === undefined && phoneNumberField?.isRequired) {
			unfilled.push(t('loc:general|Phone number'))
		}
		if (fromValues?.bankAccountNumber === undefined && bankAccountNumberField?.isRequired) {
			unfilled.push(t('loc:general|IBAN'))
		}
		if (fromValues?.street === undefined && streetField?.isRequired) {
			unfilled.push(t('loc:general|Street'))
		}
		if (fromValues?.houseNumber === undefined && streetField?.isRequired) {
			unfilled.push(t('loc:general|House number'))
		}
		if (fromValues?.city === undefined && streetField?.isRequired) {
			unfilled.push(t('loc:general|City'))
		}
		if (fromValues?.zipCode === undefined && streetField?.isRequired) {
			unfilled.push(t('loc:general|Zip Code'))
		}
		if (!fromValues?.consent) {
			unfilled.push(t('loc:general|consent'))
		}
		return {
			disabled: unfilled.length !== 0,
			error: `${t('loc:general|Please fill up required fields')}: ${unfilled.join(', ')}`
		}
	}

	return (
		<Form onSubmitCapture={handleSubmit}>
			<h3 className={'text-18 font-700 mb-4 mt-[30px]'}>{'Osobne údaje'}</h3>
			<Row justify={'space-between'} gutter={16}>
				<Col span={isMobile ? 24 : 12}>
					{businessNameField && (
						<Field
							name={'businessName'}
							component={InputField}
							label={businessNameField?.label}
							placeholder={businessNameField?.placeholder}
							required={businessNameField?.isRequired}
							className={'mb-4'}
							readOnly={submitting}
						/>
					)}
				</Col>
				<Col span={isMobile ? 24 : 12}>
					{businessRegistrationNumberField && (
						<Field
							name={'businessRegistrationNumber'}
							component={InputField}
							label={businessRegistrationNumberField?.label}
							placeholder={businessRegistrationNumberField?.placeholder}
							required={businessRegistrationNumberField?.isRequired}
							className={'mb-4'}
							readOnly={submitting}
						/>
					)}
				</Col>
			</Row>

			<Row justify={'space-between'} gutter={16}>
				<Col span={isMobile ? 24 : 12}>
					{firstNameField && (
						<Field
							name={'firstName'}
							component={InputField}
							label={firstNameField.label}
							placeholder={firstNameField.placeholder}
							required={firstNameField.isRequired}
							className={'mb-4'}
							readOnly={submitting}
						/>
					)}
				</Col>
				<Col span={isMobile ? 24 : 12}>
					{lastNameField && (
						<Field
							name={'lastName'}
							component={InputField}
							label={lastNameField.label}
							placeholder={lastNameField.placeholder}
							required={lastNameField.isRequired}
							className={'mb-4'}
							readOnly={submitting}
						/>
					)}
				</Col>
			</Row>

			{employeeIdentifierField && (
				<Field
					name={'employeeIdentifier'}
					component={InputField}
					label={employeeIdentifierField.label}
					placeholder={employeeIdentifierField.placeholder}
					required={employeeIdentifierField.isRequired}
					className={'mb-4'}
					readOnly={submitting}
				/>
			)}

			{emailField && (
				<Field
					name={'email'}
					component={InputField}
					label={emailField.label}
					placeholder={emailField.placeholder}
					required={emailField.isRequired}
					className={'mb-4'}
					readOnly={submitting}
				/>
			)}

			{birthdayField && (
				<Field
					name={'birthday'}
					component={DatePickerField}
					label={birthdayField.label}
					placeholder={birthdayField.placeholder}
					required={birthdayField.isRequired}
					className={'mb-4'}
					readOnly={submitting}
				/>
			)}

			{phoneNumberField && (
				<Field
					name={'phoneNumber'}
					component={PhoneNumberField}
					label={phoneNumberField.label}
					placeholder={phoneNumberField.placeholder}
					required={phoneNumberField.isRequired}
					className={'mb-4'}
					readOnly={submitting}
				/>
			)}

			{bankAccountNumberField && (
				<Field
					name={'bankAccountNumber'}
					component={InputField}
					label={bankAccountNumberField.label}
					placeholder={bankAccountNumberField.placeholder}
					required={bankAccountNumberField.isRequired}
					className={'mb-4'}
					readOnly={submitting}
				/>
			)}

			<h3 className={'text-18 font-700 mb-4 mt-[30px]'}>{'Adresa'}</h3>

			<Row justify={'space-between'} gutter={16}>
				<Col span={isMobile ? 24 : 12}>
					{streetField && (
						<Field
							name={'street'}
							component={SelectField}
							label={streetField.label}
							placeholder={streetField.placeholder}
							required={streetField.isRequired}
							className={'mb-4'}
							readOnly={submitting || !ready}
							showSearch
							showArrow={false}
							notFoundContent={null}
							searchValue={fromValues?.street}
							onSearch={handleSearch}
							onChange={handleSelect}
							options={map(data || [], (item: any) => ({
								// NOTE: Antd Select allows to use only string values
								value: removeDiacritics(item.description),
								label: item.description
							}))}
						/>
					)}
				</Col>
				<Col span={isMobile ? 24 : 12}>
					{streetField && (
						<Field
							name={'houseNumber'}
							component={InputField}
							label={t('loc:general|HouseNumber')}
							placeholder={t('loc:general|type house number')}
							required
							className={'mb-4'}
							readOnly={submitting}
						/>
					)}
				</Col>
			</Row>

			{cityField && (
				<Field
					name={'city'}
					component={InputField}
					label={cityField.label}
					placeholder={cityField.placeholder}
					required={cityField.isRequired}
					className={'mb-4'}
					readOnly={submitting}
				/>
			)}

			{zipCodeField && (
				<Field
					name={'zipCode'}
					component={InputField}
					label={zipCodeField.label}
					placeholder={zipCodeField.placeholder}
					required={zipCodeField.isRequired}
					className={'mb-4'}
					readOnly={submitting}
				/>
			)}

			<Row justify={'space-between'} align={'middle'} className={'mt-10 mb-6'}>
				<Col span={'16'}>
					<span className={'inline-block text-13 break-words'}>
						{`${t('loc:general|I agree with')} `}
						<a href={'https://www.wagenow.sk/vop'} className={'underline'} target={'_blank'} rel={'noopener noreferrer'}>
							{t('loc:general|with Terms and Conditions')}
						</a>
					</span>
				</Col>
				<Col span={'8'} className={'text-right'}>
					<Field name={'consent'} component={SwitchField} required={consentField?.isRequired} />
				</Col>
			</Row>
			<div className={'flex flex-col items-center gap-y-2 lg:gap-y-4 mt-10'}>
				<Button
					className={'max-w-[340px]'}
					type={'primary'}
					shape={'round'}
					block={true}
					htmlType={'submit'}
					loading={submitting}
					disabled={submitDisabled().disabled}
				>
					{t('loc:general|Submit')}
				</Button>
				{submitDisabled().disabled && (
					<p className={'form-field-message'} style={{ color: 'red' }}>
						{submitDisabled().error}
					</p>
				)}
			</div>
		</Form>
	)
}

export default reduxForm<IWelcomeFormValues, IWelcomeForm>({
	form: FORM.ONBOARDING,
	validate: validateWelcomeForm,
	touchOnChange: true,
	destroyOnUnmount: true,
	forceUnregisterOnUnmount: true
})(WelcomeForm)
