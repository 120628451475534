import { IForgotPasswordFormValues } from '../../pages/auth/forgot-password/ForgotPasswordForm'
import { ILoginFormValues } from '../../pages/auth/login/LoginForm'
import { IResetPasswordFormValues } from '../../pages/auth/reset-password/ResetPasswordForm'
import { IAuthTokens, ILoadingAndFailure, IUser } from '../../types/interfaces'
import { clearAccessToken, clearRefreshToken, setAccessToken, setRefreshToken } from '../../utils/auth'
import { NOTIFICATION_TYPE } from '../../utils/enums'
import { deleteReq, postReq } from '../../utils/requests'
import { STORE } from '../generalTypes'
import { ThunkResult } from '../index'
import { mapUserAuthLoginResponse, mapUserAuthRefreshTokenResponse } from './userAuthHelpers'
import {
	USER_AUTH_DEACTIVATE,
	USER_AUTH_FORGOT_PASSWORD,
	USER_AUTH_LOGIN,
	USER_AUTH_LOGOUT,
	USER_AUTH_REFRESH_TOKEN,
	USER_AUTH_RESEND_INVITATION,
	USER_AUTH_RESET_PASSWORD
} from './userAuthTypes'

export interface IUserAuthPayload extends ILoadingAndFailure {
	user?: IUser
	authTokens?: IAuthTokens
}

/* Reset password */

interface IUserAuthResetPassword {
	type: USER_AUTH_RESET_PASSWORD
	payload: IUserAuthPayload
}

export const userAuthResetPassword =
	(values: IResetPasswordFormValues, authToken: string, onSuccess: () => void): ThunkResult<void> =>
	async (dispatch) => {
		try {
			dispatch({ type: USER_AUTH_RESET_PASSWORD.START })
			await postReq('/api/v1/auth/reset-password', undefined, { password: values.password }, { headers: { Authorization: `Bearer ${authToken}` } }, false)
			dispatch({ type: USER_AUTH_RESET_PASSWORD.DONE })
			onSuccess()
		} catch (error) {
			dispatch({ type: USER_AUTH_RESET_PASSWORD.FAIL })
		}
	}

/* Login */

export interface IUserAuthLogin {
	type: USER_AUTH_LOGIN
	payload: IUserAuthPayload
}

export const userAuthLogin =
	(values: ILoginFormValues, onSuccess: () => void, onFailure: () => void): ThunkResult<void> =>
	async (dispatch) => {
		try {
			dispatch({ type: USER_AUTH_LOGIN.START })
			const { data } = await postReq('/api/v1/auth/login', undefined, values)
			setAccessToken(data.accessToken)
			setRefreshToken(data.refreshToken)
			dispatch({ type: USER_AUTH_LOGIN.DONE, payload: mapUserAuthLoginResponse(data) })
			onSuccess()
		} catch (error) {
			dispatch({ type: USER_AUTH_LOGIN.FAIL })
			onFailure()
		}
	}

/* Logout */

interface IUserAuthLogout {
	type: USER_AUTH_LOGOUT
	payload: undefined
}

export const userAuthLogout =
	(showNotification: boolean, onSuccess?: () => void): ThunkResult<void> =>
	async (dispatch) => {
		try {
			dispatch({ type: USER_AUTH_LOGOUT.START })
			postReq('/api/v1/auth/logout', undefined, undefined, undefined, showNotification ? NOTIFICATION_TYPE.NOTIFICATION : false)
			clearAccessToken()
			clearRefreshToken()
			dispatch({ type: STORE.RESET })
			dispatch({ type: USER_AUTH_LOGOUT.DONE })
			if (onSuccess) onSuccess()
		} catch (error) {
			dispatch({ type: USER_AUTH_LOGOUT.FAIL })
		}
	}

/* Forgot password */

interface IUserAuthForgotPassword {
	type: USER_AUTH_FORGOT_PASSWORD
	payload: IUserAuthPayload
}

export const userAuthForgotPassword =
	(values: IForgotPasswordFormValues, onSuccess: () => void): ThunkResult<void> =>
	async (dispatch) => {
		try {
			dispatch({ type: USER_AUTH_FORGOT_PASSWORD.START })
			await postReq('/api/v1/auth/forgot-password', undefined, values, {}, false)
			dispatch({ type: USER_AUTH_FORGOT_PASSWORD.DONE })
			onSuccess()
		} catch (error) {
			dispatch({ type: USER_AUTH_FORGOT_PASSWORD.FAIL })
		}
	}

/* Refresh token */

interface IUserAuthRefreshToken {
	type: USER_AUTH_REFRESH_TOKEN
	payload: IUserAuthPayload
}

export const userAuthRefreshToken =
	(token: string): ThunkResult<void> =>
	async (dispatch) => {
		try {
			dispatch({ type: USER_AUTH_REFRESH_TOKEN.START })
			const { data } = await postReq('/api/v1/auth/refresh-token', undefined, { refreshToken: token })
			setAccessToken(data.accessToken)
			setRefreshToken(data.refreshToken)
			dispatch({ type: USER_AUTH_REFRESH_TOKEN.DONE, payload: mapUserAuthRefreshTokenResponse(data) })
		} catch (error) {
			dispatch({ type: USER_AUTH_REFRESH_TOKEN.FAIL })
		}
	}

/* Deactivate */

export const userAuthDeactivate =
	(id: number, onSuccess: () => void): ThunkResult<void> =>
	async (dispatch) => {
		try {
			dispatch({ type: USER_AUTH_DEACTIVATE.START })
			await deleteReq('/api/admin/users/{userID}', { userID: id }, {}, false)
			dispatch({ type: USER_AUTH_DEACTIVATE.DONE })
			onSuccess()
		} catch (error) {
			dispatch({ type: USER_AUTH_DEACTIVATE.FAIL })
		}
	}

/* Resend invitation */

export const userAuthResendInvitation =
	(id: number, onSuccess: () => void): ThunkResult<void> =>
	async (dispatch) => {
		try {
			dispatch({ type: USER_AUTH_RESEND_INVITATION.START })
			await postReq('/api/admin/users/resend-invitation', undefined, { userID: id }, { headers: { 'accept-language': 'sk' } })
			dispatch({ type: USER_AUTH_RESEND_INVITATION.DONE })
			onSuccess()
		} catch (error) {
			dispatch({ type: USER_AUTH_RESEND_INVITATION.FAIL })
		}
	}

/* Actions */

export type IUserAuthAction = IUserAuthResetPassword | IUserAuthLogin | IUserAuthLogout | IUserAuthForgotPassword | IUserAuthRefreshToken
