import i18next from 'i18next'
import { isValidIBAN } from 'ibantools'
import { isEmail } from 'lodash-checkit'
import { FormErrors } from 'redux-form'
import dayjs from 'dayjs'
import { includes } from 'lodash'
import { IWelcomeFormValues } from './WelcomeForm'

export default (values: IWelcomeFormValues) => {
	const errors: FormErrors<IWelcomeFormValues> = {}

	if (values.businessRegistrationNumber && Number.isNaN(+values.businessRegistrationNumber)) {
		errors.businessRegistrationNumber = i18next.t('loc:general|Company id must be number')
	}

	if ((values.firstName?.length || 0) > 255) {
		errors.firstName = i18next.t('loc:general|Maximum number of characters is {{max}}', { max: 255 })
	}

	if ((values.lastName?.length || 0) > 255) {
		errors.lastName = i18next.t('loc:general|Maximum number of characters is {{max}}', { max: 255 })
	}

	if (values.email) {
		if (values.email.length > 255) {
			errors.email = i18next.t('loc:general|Maximum number of characters is {{max}}', { max: 255 })
		} else if (!isEmail(values.email)) {
			errors.email = i18next.t('loc:general|Email format is incorrect')
		}
	}

	if (values.bankAccountNumber) {
		if (values.bankAccountNumber.length > 255) {
			errors.bankAccountNumber = i18next.t('loc:general|Maximum number of characters is {{max}}', { max: 255 })
		} else if (!isValidIBAN(values.bankAccountNumber.replace(/\s/g, ''))) {
			errors.bankAccountNumber = i18next.t('loc:admin|IBAN format is incorrect')
		}
	}

	if (dayjs(values.birthday).isAfter(dayjs())) {
		errors.birthday = i18next.t('loc:general|Date cannot be in the future')
	}

	if (!values.phoneNumber) {
		errors.phoneNumber = i18next.t('loc:general|This field is required')
	}

	if (values.phoneNumber && (values.phoneNumber || [])[0] === '0') {
		errors.phoneNumber = i18next.t('loc:general|Number cannot start with 0')
	}

	if (values.phoneNumber && (values.phoneNumber || [])[10] !== '-' && includes(values.phoneNumber, '-')) {
		errors.phoneNumber = i18next.t('loc:general|Number is not completed')
	}

	if (values.consent === false) {
		errors.consent = i18next.t('loc:general|This field is required')
	}

	return errors
}
