import { Col, Row } from 'antd'
import { FC, ReactNode } from 'react'
import { useTranslation } from 'react-i18next'

interface IProps {
	hideTitle?: boolean
	children: ReactNode
}

const PublicLayout: FC<IProps> = ({ children, hideTitle = false }) => {
	const { t } = useTranslation()

	return (
		<section className={'bg-white'}>
			{!hideTitle ? (
				<Row justify={'center'} align={'middle'}>
					<Col xs={{ span: 24 }} lg={{ span: 20 }}>
						<div className={'py-8 px-4'}>
							<h1 className={'text-center text-26 lg:text-56 font-700 mb-4 text-gradient'}>{t('loc:general|WageNow')}</h1>
							{children}
						</div>
					</Col>
				</Row>
			) : (
				children
			)}
		</section>
	)
}

export default PublicLayout
