import { IStoreAction, STORE } from '../generalTypes'
import { IWithdrawalsAction, IWithdrawalsLoadDetailPayload, IWithdrawalsLoadListByUserPayload, IWithdrawalsLoadListPayload } from './withdrawalsActions'
import { WITHDRAWALS_LOAD_DETAIL, WITHDRAWALS_LOAD_LIST, WITHDRAWALS_LOAD_LIST_BY_USER } from './withdrawalsTypes'

const initState: {
	list: IWithdrawalsLoadListPayload
	detail: IWithdrawalsLoadDetailPayload
	listByUser: IWithdrawalsLoadListByUserPayload
} = {
	list: {
		records: undefined,
		pagination: undefined,
		isLoading: false,
		isFailure: false
	},
	detail: {
		record: undefined,
		isLoading: false,
		isFailure: false
	},
	listByUser: {
		records: undefined,
		pagination: undefined,
		isLoading: false,
		isFailure: false
	}
}

export default (state = initState, action: IWithdrawalsAction | IStoreAction) => {
	switch (action.type) {
		case WITHDRAWALS_LOAD_LIST_BY_USER.START:
			return {
				...state,
				listByUser: {
					...state.listByUser,
					isLoading: true,
					isFailure: false
				}
			}
		case WITHDRAWALS_LOAD_LIST.START:
			return {
				...state,
				list: {
					...state.list,
					isLoading: true,
					isFailure: false
				}
			}
		case WITHDRAWALS_LOAD_LIST_BY_USER.DONE:
			return {
				...state,
				listByUser: {
					...state.listByUser,
					...action.payload,
					isLoading: false,
					isFailure: false
				}
			}
		case WITHDRAWALS_LOAD_LIST.DONE:
			return {
				...state,
				list: {
					...state.list,
					...action.payload,
					isLoading: false,
					isFailure: false
				}
			}
		case WITHDRAWALS_LOAD_LIST_BY_USER.FAIL:
			return {
				...state,
				listByUser: {
					...state.listByUser,
					isLoading: false,
					isFailure: true
				}
			}
		case WITHDRAWALS_LOAD_LIST.FAIL:
			return {
				...state,
				list: {
					...state.list,
					isLoading: false,
					isFailure: true
				}
			}
		case WITHDRAWALS_LOAD_LIST_BY_USER.RESET:
			return {
				...state,
				listByUser: {
					...initState.listByUser
				}
			}
		case WITHDRAWALS_LOAD_DETAIL.START:
			return {
				...state,
				detail: {
					...state.detail,
					isLoading: true,
					isFailure: false
				}
			}
		case WITHDRAWALS_LOAD_DETAIL.DONE:
			return {
				...state,
				detail: {
					...state.detail,
					...action.payload,
					isLoading: false,
					isFailure: false
				}
			}
		case WITHDRAWALS_LOAD_DETAIL.FAIL:
			return {
				...state,
				detail: {
					...state.detail,
					isLoading: false,
					isFailure: true
				}
			}
		case WITHDRAWALS_LOAD_DETAIL.RESET:
			return {
				...state,
				detail: {
					...initState.detail
				}
			}
		case STORE.RESET:
			return initState
		default:
			return state
	}
}
