import { Empty, Spin, Table, TableColumnProps } from 'antd'
import cx from 'classnames'
import dayjs from 'dayjs'
import { FC, useEffect, useLayoutEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { ReactComponent as CheckIcon } from '../../../assets/icons/check.svg'
import { ReactComponent as CrossIcon } from '../../../assets/icons/cross.svg'
import { RootState } from '../../../redux'
import { IEmployeesLoadDetailPayload } from '../../../redux/employees/employeesActions'
import { IWithdrawalsLoadListByUserPayload, withdrawalsLoadListByUser } from '../../../redux/withdrawals/withdrawalsActions'
import { IPaginationResponse, IWithdrawal } from '../../../types/interfaces'
import { WITHDRAWAL_STATE } from '../../../utils/enums'
import { formatAsCurrency } from '../../../utils/helpers'

type IWithdrawalsDashboardHistory = {}

export interface IWithdrawalsHistoryFilterValues {
	page?: IPaginationResponse['page']
	limit?: IPaginationResponse['limit']
}

export const withdrawalsHistoryFilterDefaultState: IWithdrawalsHistoryFilterValues = {
	page: 1,
	limit: 5
}

const WithdrawalsDashboardHistory: FC<IWithdrawalsDashboardHistory> = () => {
	const { t } = useTranslation()
	const dispatch = useDispatch()

	const employee: IEmployeesLoadDetailPayload = useSelector((state: RootState) => state.employees.detail)
	const withdrawals: IWithdrawalsLoadListByUserPayload = useSelector((state: RootState) => {
		return state.withdrawals.listByUser
	})

	const [filter, setFilter] = useState<IWithdrawalsHistoryFilterValues>(withdrawalsHistoryFilterDefaultState)
	const [dataSource, setDataSource] = useState<IWithdrawalsLoadListByUserPayload['records']>(withdrawals.records)

	useEffect(() => {
		if (!employee.record) return
		dispatch(
			withdrawalsLoadListByUser(employee.record.id, {
				...filter,
				exceptWithdrawalIDS:
					employee.record.withdrawalState === WITHDRAWAL_STATE.PENDING && employee.record.withdrawalID ? [employee.record.withdrawalID] : undefined
			})
		)
	}, [dispatch, employee, filter])

	useLayoutEffect(() => {
		setDataSource(withdrawals.records)
	}, [withdrawals.records])

	if (!employee.record) return null

	const columns: TableColumnProps<IWithdrawal>[] = [
		{
			key: 'withdrawal',
			align: 'left',
			render: (record: IWithdrawal) => (
				<>
					<span className={'block text-12 font-500 whitespace-nowrap text-gray-500 mb-1'}>
						{dayjs(record.updatedDate || record.createdDate).format('D MMMM')}
					</span>
					<span className={'flex items-center gap-2 whitespace-nowrap'}>
						<span
							className={cx(
								'inline-flex justify-center items-center w-5 h-5 text-10 text-white rounded-full shadow-sm',
								{ 'bg-success shadow-success/50%': record.state === WITHDRAWAL_STATE.APPROVED },
								{ 'bg-error shadow-error/50%': record.state === WITHDRAWAL_STATE.REJECTED }
							)}
							style={{ marginLeft: 6 }}
						>
							{record.state === WITHDRAWAL_STATE.APPROVED && <CheckIcon />}
							{record.state === WITHDRAWAL_STATE.REJECTED && <CrossIcon />}
						</span>
						<span className={'text-17 font-400'}>
							{record.state === WITHDRAWAL_STATE.APPROVED && t('loc:employee|Withdrawal Accepted')}
							{record.state === WITHDRAWAL_STATE.REJECTED && t('loc:employee|Withdrawal Rejected')}
						</span>
					</span>
				</>
			)
		},
		{
			key: 'amount',
			dataIndex: 'amount',
			align: 'right',
			render: (value: IWithdrawal['amount']) => <span className={'text-17 font-400 whitespace-nowrap'}>{`-${formatAsCurrency(value)}`}</span>
		}
	]

	return (
		<>
			<h2 className={'text-17 lg:text-22 font-600 mb-1 lg:mb-3'}>{t('loc:employee|Withdrawal History')}</h2>
			<Table
				scroll={{ x: true }}
				loading={{
					indicator: <Spin size={'large'} />,
					spinning: withdrawals.isLoading
				}}
				columns={columns}
				dataSource={dataSource}
				rowKey={(record) => record.id}
				locale={{ emptyText: <Empty description={t('loc:general|No Data')} /> }}
				pagination={{
					position: ['bottomCenter'],
					showSizeChanger: false,
					hideOnSinglePage: true,
					total: withdrawals.pagination?.totalCount,
					current: withdrawals.pagination?.page,
					pageSize: withdrawals.pagination?.limit
				}}
				onChange={(pagination) => setFilter({ ...filter, page: pagination.current })}
				className={'withdrawals-dashboard-history-table'}
			/>
		</>
	)
}

export default WithdrawalsDashboardHistory
