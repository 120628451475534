import { IStoreAction, STORE } from '../generalTypes'
import { IEmployeesAction, IEmployeesLoadDetailPayload, IEmployeesLoadListPayload } from './employeesActions'
import { EMPLOYEES_LOAD_DETAIL, EMPLOYEES_LOAD_LIST } from './employeesTypes'

const initState: {
	list: IEmployeesLoadListPayload
	detail: IEmployeesLoadDetailPayload
} = {
	list: {
		records: undefined,
		pagination: undefined,
		isLoading: false,
		isFailure: false
	},
	detail: {
		record: undefined,
		isLoading: false,
		isFailure: false
	}
}

export default (state = initState, action: IEmployeesAction | IStoreAction) => {
	switch (action.type) {
		case EMPLOYEES_LOAD_LIST.START:
			return {
				...state,
				list: {
					...state.list,
					isLoading: true,
					isFailure: false
				}
			}
		case EMPLOYEES_LOAD_LIST.DONE:
			return {
				...state,
				list: {
					...state.list,
					...action.payload,
					isLoading: false,
					isFailure: false
				}
			}
		case EMPLOYEES_LOAD_LIST.FAIL:
			return {
				...state,
				list: {
					...state.list,
					isLoading: false,
					isFailure: true
				}
			}
		case EMPLOYEES_LOAD_DETAIL.START:
			return {
				...state,
				detail: {
					...state.detail,
					isLoading: true,
					isFailure: false
				}
			}
		case EMPLOYEES_LOAD_DETAIL.DONE:
			return {
				...state,
				detail: {
					...state.detail,
					...action.payload,
					isLoading: false,
					isFailure: false
				}
			}
		case EMPLOYEES_LOAD_DETAIL.FAIL:
			return {
				...state,
				detail: {
					...state.detail,
					isLoading: false,
					isFailure: true
				}
			}
		case EMPLOYEES_LOAD_DETAIL.RESET:
			return {
				...state,
				detail: {
					...initState.detail
				}
			}
		case STORE.RESET:
			return initState
		default:
			return state
	}
}
