import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { withdrawalsLoadList } from '../../redux/withdrawals/withdrawalsActions'
import WithdrawalsListFilter, { IWithdrawalsListFilterValues, withdrawalsListFilterDefaultState } from './list/WithdrawalsListFilter'
import WithdrawalsListTable from './list/WithdrawalsListTable'

const WithdrawalsList: FC = () => {
	const { t } = useTranslation()
	const dispatch = useDispatch()

	const [filter, setFilter] = useState<IWithdrawalsListFilterValues>(withdrawalsListFilterDefaultState)

	useEffect(() => {
		document.title = `${t('loc:admin|Withdrawals')} - ${t('loc:general|WageNow')}`
	})

	useEffect(() => {
		dispatch(withdrawalsLoadList(filter))
	}, [dispatch, filter])

	return (
		<>
			<WithdrawalsListFilter filter={filter} setFilter={setFilter} />
			<WithdrawalsListTable filter={filter} setFilter={setFilter} />
		</>
	)
}

export default WithdrawalsList
