import { Button } from 'antd'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Field, Form, InjectedFormProps, reduxForm } from 'redux-form'
import checkMarkImage from '../../assets/images/check-mark.png'
import InputField from '../../atoms/form/InputField'
import { FORM } from '../../utils/enums'
import validateCreateAdminForm from './validateCreateAdminForm'

interface ICreateAdminForm {
	submitSuccess: boolean
	handleModalClose: () => void
}

export interface ICreateAdminFormValues {
	firstName: string
	lastName: string
	email: string
}

const CreateAdminForm: FC<InjectedFormProps<ICreateAdminFormValues, ICreateAdminForm> & ICreateAdminForm> = ({
	handleSubmit,
	submitting,
	submitSuccess,
	handleModalClose
}) => {
	const { t } = useTranslation()

	return submitSuccess ? (
		<>
			<img src={checkMarkImage} alt={''} className={'self-center mb-6'} style={{ width: '100px' }} />
			<h4 className={'text-center text-28 font-700 mb-2'}>{t('loc:admin|Account Created')}</h4>
			<p className={'text-center text-17 font-400 mb-8'}>{`${t('loc:admin|Invitation email was sent')}.`}</p>
			<Button type={'primary'} shape={'round'} block={true} onClick={handleModalClose}>
				{t('loc:general|Close')}
			</Button>
		</>
	) : (
		<Form noValidate={true} onSubmitCapture={handleSubmit}>
			<h4 className={'text-28 font-700 mb-6'}>{t('loc:admin|Add New Admin')}</h4>
			<Field name={'firstName'} component={InputField} placeholder={t('loc:admin|Name')} className={'mb-4'} />
			<Field name={'lastName'} component={InputField} placeholder={t('loc:admin|Surname')} className={'mb-4'} />
			<Field name={'email'} component={InputField} placeholder={t('loc:general|Email')} className={'mb-10'} />
			<Button type={'primary'} shape={'round'} block={true} htmlType={'submit'} disabled={submitting} loading={submitting}>
				{t('loc:admin|Create Account')}
			</Button>
		</Form>
	)
}

export default reduxForm<ICreateAdminFormValues, ICreateAdminForm>({
	form: FORM.ADMIN_CREATE,
	validate: validateCreateAdminForm,
	touchOnChange: true,
	destroyOnUnmount: true,
	forceUnregisterOnUnmount: true
})(CreateAdminForm)
