import i18next from 'i18next'
import { isEmail } from 'lodash-checkit'
import { FormErrors } from 'redux-form'
import { IForgotPasswordFormValues } from './ForgotPasswordForm'

export default (values: IForgotPasswordFormValues) => {
	const errors: FormErrors<IForgotPasswordFormValues> = {}

	if (!values.email) {
		errors.email = i18next.t('loc:general|This field is required')
	} else if (values.email.length > 255) {
		errors.email = i18next.t('loc:general|Maximum number of characters is {{max}}', { max: 255 })
	} else if (!isEmail(values.email)) {
		errors.email = i18next.t('loc:general|Email format is incorrect')
	}

	return errors
}
