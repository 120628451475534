import { Button, notification, Popover } from 'antd'
import { FC, PropsWithChildren } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { ReactComponent as LogoutIcon } from '../../assets/icons/logout.svg'
import { ReactComponent as SuccessIcon } from '../../assets/icons/success.svg'
import UserAvatar from '../../atoms/UserAvatar'
import { RootState } from '../../redux'
import { userAuthLogout } from '../../redux/userAuth/userAuthActions'
import { IUser } from '../../types/interfaces'
import { USER_ROLE } from '../../utils/enums'

type ILogoutPopover = PropsWithChildren

const LogoutPopover: FC<ILogoutPopover> = ({ children }) => {
	const { t } = useTranslation()
	const navigate = useNavigate()
	const dispatch = useDispatch()

	const user: IUser | undefined = useSelector((state: RootState) => state.userAuth.user)

	if (!user) return null

	const handleLogout = () => {
		dispatch(
			userAuthLogout(false, () => {
				navigate(user.role === USER_ROLE.ADMIN ? t('paths:admin/login') : t('paths:login'))
				notification.success({
					icon: <SuccessIcon />,
					message: <span className={'text-success-medium'}>{t('loc:general|Success') as string}</span>,
					description: `${t('loc:general|You have been successfully logged out')}.`
				})
			})
		)
	}

	return (
		<Popover
			trigger={'click'}
			placement={'bottomRight'}
			content={
				<div className={'inline-flex flex-col items-center gap-1 bg-white p-6 rounded-md shadow-lg mt-10 lg:mt-8'}>
					<UserAvatar user={user} showName={false} wrapperClassName={'mb-2'} />
					<span className={'block text-16 font-600'}>{`${user?.firstName} ${user?.lastName}`}</span>
					<span className={'block text-14 font-400 text-gray-500'}>{user?.email}</span>
					<Button type={'ghost'} shape={'round'} size={'small'} onClick={handleLogout} className={'mt-3'}>
						<LogoutIcon className={'text-15'} />
						<span>{t('loc:general|Log Out')}</span>
					</Button>
				</div>
			}
		>
			<Button type={'text'}>{children}</Button>
		</Popover>
	)
}

export default LogoutPopover
