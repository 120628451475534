import { Button } from 'antd'
import { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import { ReactComponent as CardIcon } from '../assets/icons/card.svg'
import { ReactComponent as ChevronIcon } from '../assets/icons/chevron.svg'

interface IBreadcrumb {
	path: string
	label: string
}

const Breadcrumb: FC<IBreadcrumb> = ({ path, label }) => {
	const navigate = useNavigate()

	return (
		<div className={'inline-flex items-center gap-2 text-gray-500 mt-2 mb-4 lg:mb-6'}>
			<CardIcon className={'text-16'} />
			<ChevronIcon className={'text-12 -rotate-90'} />
			<Button type={'text'} className={'text-15 opacity-50% hover:opacity-100%'} onClick={() => navigate(path)}>
				{label}
			</Button>
		</div>
	)
}

export default Breadcrumb
