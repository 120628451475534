import { Modal } from 'antd'
import { FC, PropsWithChildren } from 'react'

interface IBasicModal extends PropsWithChildren {
	visible: boolean
	onClose: () => void
	width?: string
	maxHeight?: string
}

const BasicModal: FC<IBasicModal> = ({ visible, onClose, width = '50vw', maxHeight = 'auto', children }) => {
	return (
		<Modal visible={visible} centered={true} closable={false} footer={false} destroyOnClose={true} width={width} onCancel={onClose}>
			<div className={'relative flex flex-col bg-white p-4 sm:p-6 md:p-8 lg:p-10 rounded-md shadow-md'} style={{ maxHeight }}>
				{children}
			</div>
		</Modal>
	)
}

export default BasicModal
