// eslint-disable-next-line import/prefer-default-export
export function shouldBeRounded(value: number | string, multiplier: number, max?: number) {
	const numberValue = typeof value === 'string' ? parseFloat(value) : value
	let roundTo = Math.floor(numberValue / multiplier) * multiplier
	if (max && roundTo > max) {
		roundTo = Math.floor(max / multiplier) * multiplier
	}

	return {
		shouldRound: roundTo !== numberValue,
		isEmpty: numberValue === 0,
		roundTo: roundTo < multiplier ? multiplier : roundTo
	}
}

export function sanitizeNumber(value: string) {
	return value
		.replace(/,/g, '.')
		.replace(/\.+/, '.')
		.replace(/[^0-9.]+/g, '')
}
