import i18next from 'i18next'
import { isValidIBAN } from 'ibantools'
import { isEmail } from 'lodash-checkit'
import { FormErrors } from 'redux-form'
import { IEmployeeAccountInfoFormValues } from './EmployeeAccountInfoForm'

export default (values: IEmployeeAccountInfoFormValues) => {
	const errors: FormErrors<IEmployeeAccountInfoFormValues> = {}

	if (!values.firstName) {
		errors.firstName = i18next.t('loc:general|This field is required')
	} else if (values.firstName.length > 255) {
		errors.firstName = i18next.t('loc:general|Maximum number of characters is {{max}}', { max: 255 })
	}

	if (!values.lastName) {
		errors.lastName = i18next.t('loc:general|This field is required')
	} else if (values.lastName.length > 255) {
		errors.lastName = i18next.t('loc:general|Maximum number of characters is {{max}}', { max: 255 })
	}

	if (!values.email) {
		errors.email = i18next.t('loc:general|This field is required')
	} else if (values.email.length > 255) {
		errors.email = i18next.t('loc:general|Maximum number of characters is {{max}}', { max: 255 })
	} else if (!isEmail(values.email)) {
		errors.email = i18next.t('loc:general|Email format is incorrect')
	}

	if (!values.companyID) {
		errors.companyID = i18next.t('loc:general|This field is required')
	}

	if (!values.bankAccountNumber) {
		errors.bankAccountNumber = i18next.t('loc:general|This field is required')
	} else if (values.bankAccountNumber.length > 255) {
		errors.bankAccountNumber = i18next.t('loc:general|Maximum number of characters is {{max}}', { max: 255 })
	} else if (!isValidIBAN(values.bankAccountNumber)) {
		errors.bankAccountNumber = i18next.t('loc:admin|IBAN format is incorrect')
	}

	return errors
}
