import * as Sentry from '@sentry/react'
import { Button, Result } from 'antd'
import { t } from 'i18next'
import { Component, ReactNode } from 'react'

class ErrorBoundary extends Component<{ children: ReactNode }> {
	state = {
		error: null,
		eventId: null as any,
		isOpen: false
	}

	static getDerivedStateFromError() {
		return { hasError: true }
	}

	componentDidCatch(error: any, errorInfo: any) {
		this.setState({ error })
		Sentry.withScope((scope: any) => {
			scope.setExtras(errorInfo)
			const eventId = Sentry.captureException(error)
			this.setState({ eventId })
		})
	}

	render() {
		if (this.state.error) {
			return (
				<div className={'h-100% flex justify-center items-center m-4'}>
					<Result
						status={'500'}
						title={'500'}
						subTitle={`${t('loc:general|Unexpected error occurred and your request could not be completed')}. ${t(
							'loc:general|Please contact us about the error'
						)}.`}
						extra={
							<>
								<Button type={'primary'} shape={'round'} onClick={() => this.setState({ isOpen: true })}>
									{'Report an issue'}
								</Button>
								{this.state.isOpen &&
									Sentry.showReportDialog({
										eventId: this.state.eventId,
										successMessage: 'Your feedback has been sent. Thank you!',
										title: 'Unexpected error occurred',
										subtitle: 'Please contact us',
										subtitle2: '',
										labelName: 'Name',
										labelComments: 'Error description',
										labelClose: 'Close',
										labelSubmit: 'Send error report',
										lang: 'en'
									})}
							</>
						}
					/>
				</div>
			)
		}
		return this.props.children
	}
}

export default ErrorBoundary
