import { IStoreAction, STORE } from '../generalTypes'
import { IUserAuthAction, IUserAuthPayload } from './userAuthActions'
import { USER_AUTH_FORGOT_PASSWORD, USER_AUTH_LOGIN, USER_AUTH_LOGOUT, USER_AUTH_REFRESH_TOKEN, USER_AUTH_RESET_PASSWORD } from './userAuthTypes'

const initState: IUserAuthPayload = {
	user: undefined,
	authTokens: undefined,
	isLoading: false,
	isFailure: false
}

export default (state = initState, action: IUserAuthAction | IStoreAction) => {
	switch (action.type) {
		case USER_AUTH_RESET_PASSWORD.START:
		case USER_AUTH_LOGIN.START:
		case USER_AUTH_LOGOUT.START:
		case USER_AUTH_FORGOT_PASSWORD.START:
		case USER_AUTH_REFRESH_TOKEN.START:
			return {
				...state,
				isLoading: true,
				isFailure: false
			}
		case USER_AUTH_RESET_PASSWORD.DONE:
		case USER_AUTH_LOGIN.DONE:
		case USER_AUTH_LOGOUT.DONE:
		case USER_AUTH_FORGOT_PASSWORD.DONE:
		case USER_AUTH_REFRESH_TOKEN.DONE:
			return {
				...state,
				...action.payload,
				isLoading: false,
				isFailure: false
			}
		case USER_AUTH_RESET_PASSWORD.FAIL:
		case USER_AUTH_LOGIN.FAIL:
		case USER_AUTH_LOGOUT.FAIL:
		case USER_AUTH_FORGOT_PASSWORD.FAIL:
		case USER_AUTH_REFRESH_TOKEN.FAIL:
			return {
				...state,
				isLoading: false,
				isFailure: true
			}
		case STORE.RESET:
			return initState
		default:
			return state
	}
}
