import i18next from 'i18next'
import { FormErrors } from 'redux-form'
import { ICreateWithdrawalFormValues } from './CreateWithdrawalForm'
import { MinimalWithdrawalValue, WithdrawalMultiplier } from '../../utils/constants'
import { shouldBeRounded } from '../../utils/numberUtils'
import { formatAsCurrency } from '../../utils/helpers'

export default (values: ICreateWithdrawalFormValues) => {
	const errors: FormErrors<ICreateWithdrawalFormValues> = {}

	const roundedData = shouldBeRounded(values.amount, WithdrawalMultiplier)

	if (!values.amount) {
		// errors.amount = i18next.t('loc:general|This field is required')
	} else if (values.amount < MinimalWithdrawalValue) {
		errors.amount = i18next.t('loc:general|Amount can not be less than 5')
	} else if (values.amount > values.availableEarnings) {
		errors.amount = i18next.t('loc:general|Amount cannot be higher then your available balance ({{balance}})', {
			balance: formatAsCurrency(values.availableEarnings)
		})
	} else if (roundedData.shouldRound) {
		errors.amount = i18next.t('loc:general|Amount should be multiple of 5')
	}

	return errors
}
