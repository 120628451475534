import { Col, Row } from 'antd'
import { FC, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import Breadcrumb from '../../atoms/Breadcrumb'
import { RootState } from '../../redux'
import { employeesLoadDetail, employeesUpdate, IEmployeesLoadDetailPayload } from '../../redux/employees/employeesActions'
import { withdrawalsLoadDetail } from '../../redux/withdrawals/withdrawalsActions'
import EmployeeAccountInfoForm, { IEmployeeAccountInfoFormValues } from '../employees/detail/EmployeeAccountInfoForm'
import EmployeeWithdrawalInfoForm, { IEmployeeWithdrawalInfoFormValues } from '../employees/detail/EmployeeWithdrawalInfoForm'
import WithdrawalDetailHistory from './detail/WithdrawalDetailHistory'
import WithdrawalDetailRequest from './detail/WithdrawalDetailRequest'
import { EMPLOYEES_LOAD_DETAIL } from '../../redux/employees/employeesTypes'
import { WITHDRAWALS_LOAD_DETAIL, WITHDRAWALS_LOAD_LIST, WITHDRAWALS_LOAD_LIST_BY_USER } from '../../redux/withdrawals/withdrawalsTypes'

const WithdrawalDetail: FC = () => {
	const { id } = useParams()
	const { t } = useTranslation()
	const navigate = useNavigate()
	const dispatch = useDispatch()

	const employee: IEmployeesLoadDetailPayload = useSelector((state: RootState) => state.employees.detail)

	const handleEmployeeWithdrawalInfoFormSubmit = async (values: IEmployeeWithdrawalInfoFormValues) => {
		if (!employee.record) return
		await dispatch(employeesUpdate({ ...employee.record, ...values }))
	}

	const handleEmployeeAccountInfoFormSubmit = async (values: IEmployeeAccountInfoFormValues) => {
		if (!employee.record) return
		await dispatch(employeesUpdate({ ...employee.record, ...values }))
	}

	useEffect(() => {
		document.title = `${t('loc:admin|Withdrawal')} - ${t('loc:general|WageNow')}`
	})

	useEffect(() => {
		return () => {
			dispatch({ type: EMPLOYEES_LOAD_DETAIL.RESET })
			dispatch({ type: WITHDRAWALS_LOAD_DETAIL.RESET })
			dispatch({ type: WITHDRAWALS_LOAD_LIST.RESET })
			dispatch({ type: WITHDRAWALS_LOAD_LIST_BY_USER.RESET })
		}
	}, [])

	useEffect(() => {
		if (id) {
			dispatch(
				withdrawalsLoadDetail(
					id,
					(withdrawalDetail) => {
						if (withdrawalDetail.record) {
							dispatch(employeesLoadDetail(withdrawalDetail.record.employee.id))
						}
					},
					() => {
						navigate(t('paths:withdrawals'), { replace: true })
					}
				)
			)
		}
	}, [id, dispatch, navigate, t])

	return (
		<>
			<Breadcrumb path={t('paths:withdrawals')} label={t('loc:admin|All withdrawals')} />
			<Row gutter={{ lg: 32, xxl: 64 }}>
				<Col xs={{ span: 24 }} lg={{ span: 14 }} xl={{ span: 16 }}>
					<WithdrawalDetailRequest />
					<WithdrawalDetailHistory />
				</Col>
				<Col xs={{ span: 24 }} lg={{ span: 10 }} xl={{ span: 8 }}>
					<EmployeeWithdrawalInfoForm onSubmit={handleEmployeeWithdrawalInfoFormSubmit} />
					<EmployeeAccountInfoForm onSubmit={handleEmployeeAccountInfoFormSubmit} />
				</Col>
			</Row>
		</>
	)
}

export default WithdrawalDetail
