import cx from 'classnames'
import { FC, PropsWithChildren } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../redux'
import { IUser } from '../types/interfaces'

interface IUserAvatar extends PropsWithChildren {
	user?: Partial<IUser>
	showName: boolean
	wrapperClassName?: string
	avatarClassName?: string
	nameClassName?: string
}

const UserAvatar: FC<IUserAvatar> = ({ user, showName, wrapperClassName, avatarClassName, nameClassName, children }) => {
	const authUser: IUser | undefined = useSelector((state: RootState) => state.userAuth.user)
	const currentUser = user || authUser

	return currentUser ? (
		<div className={cx('inline-flex items-center gap-3', wrapperClassName)} style={{ maxWidth: '100%' }}>
			<span
				className={cx(
					'w-10 h-10 inline-flex flex-shrink-0 justify-center items-center text-16 text-white bg-purple-base rounded-full overflow-hidden',
					avatarClassName
				)}
			>
				{`${currentUser.firstName?.charAt(0).toUpperCase()}${currentUser.lastName?.charAt(0).toUpperCase()}`}
			</span>
			{(showName || children) && (
				<div className={'inline-flex flex-col gap-1 overflow-hidden'}>
					{showName && <span className={cx('text-16 truncate', nameClassName)}>{`${currentUser.firstName} ${currentUser.lastName}`}</span>}
					{children}
				</div>
			)}
		</div>
	) : null
}

export default UserAvatar
