import { ICreateWithdrawalFormValues } from '../../layouts/employee/CreateWithdrawalForm'
import { IWithdrawalDetailHistoryFilterValues, withdrawalDetailHistoryFilterDefaultState } from '../../pages/withdrawals/detail/WithdrawalDetailHistory'
import { IWithdrawalsListFilterValues, withdrawalsListFilterDefaultState } from '../../pages/withdrawals/list/WithdrawalsListFilter'
import { ILoadingAndFailure, IPaginationResponse, IWithdrawal } from '../../types/interfaces'
import { WITHDRAWAL_STATE } from '../../utils/enums'
import { getReq, patchReq, postReq } from '../../utils/requests'
import { ThunkResult } from '../index'
import {
	mapWithdrawalsLoadDetailResponse,
	mapWithdrawalsLoadListByUserRequest,
	mapWithdrawalsLoadListRequest,
	mapWithdrawalsLoadListResponse
} from './withdrawalsHelpers'
import {
	WITHDRAWALS_CREATE,
	WITHDRAWALS_LOAD_DETAIL,
	WITHDRAWALS_LOAD_LIST,
	WITHDRAWALS_LOAD_LIST_BY_USER,
	WITHDRAWALS_UPDATE_STATUS
} from './withdrawalsTypes'

/* Load list */

export interface IWithdrawalsLoadListPayload extends ILoadingAndFailure {
	records?: IWithdrawal[]
	pagination?: IPaginationResponse
}

interface IWithdrawalsLoadListAction {
	type: WITHDRAWALS_LOAD_LIST
	payload: IWithdrawalsLoadListPayload
}

export const withdrawalsLoadList =
	(filter: IWithdrawalsListFilterValues = withdrawalsListFilterDefaultState): ThunkResult<void> =>
	async (dispatch) => {
		try {
			dispatch({ type: WITHDRAWALS_LOAD_LIST.START })
			const { data } = await getReq('/api/admin/withdrawals/', mapWithdrawalsLoadListRequest(filter))
			dispatch({ type: WITHDRAWALS_LOAD_LIST.DONE, payload: mapWithdrawalsLoadListResponse(data) })
		} catch (error) {
			console.log(error)

			dispatch({ type: WITHDRAWALS_LOAD_LIST.FAIL })
		}
	}

/* Load list by user */

export interface IWithdrawalsLoadListByUserPayload extends ILoadingAndFailure {
	records?: IWithdrawal[]
	pagination?: IPaginationResponse
}

interface IWithdrawalsLoadListByUserAction {
	type: WITHDRAWALS_LOAD_LIST_BY_USER
	payload: IWithdrawalsLoadListByUserPayload
}

export const withdrawalsLoadListByUser =
	(id: number, filter: IWithdrawalDetailHistoryFilterValues = withdrawalDetailHistoryFilterDefaultState): ThunkResult<void> =>
	async (dispatch) => {
		dispatch({ type: WITHDRAWALS_LOAD_LIST_BY_USER.START })
		try {
			const { data } = await getReq(`/api/v1/users/{userID}/withdrawals/`, { userID: id, ...mapWithdrawalsLoadListByUserRequest(filter) })
			dispatch({ type: WITHDRAWALS_LOAD_LIST_BY_USER.DONE, payload: mapWithdrawalsLoadListResponse(data) })
		} catch (error) {
			dispatch({ type: WITHDRAWALS_LOAD_LIST_BY_USER.FAIL })
		}
	}

/* Load detail */

export interface IWithdrawalsLoadDetailPayload extends ILoadingAndFailure {
	record?: IWithdrawal
}

interface IWithdrawalsLoadDetailAction {
	type: WITHDRAWALS_LOAD_DETAIL
	payload: IWithdrawalsLoadDetailPayload
}

export const withdrawalsLoadDetail =
	(id: string, onSuccess?: (payload: Omit<IWithdrawalsLoadDetailPayload, 'isLoading' | 'isFailure'>) => void, onError?: () => void): ThunkResult<void> =>
	async (dispatch) => {
		dispatch({ type: WITHDRAWALS_LOAD_DETAIL.START })
		try {
			const { data } = await getReq(`/api/admin/withdrawals/{withdrawalID}`, { withdrawalID: id })
			const payload = mapWithdrawalsLoadDetailResponse(data)
			dispatch({ type: WITHDRAWALS_LOAD_DETAIL.DONE, payload })
			if (onSuccess) onSuccess(payload)
		} catch (error) {
			dispatch({ type: WITHDRAWALS_LOAD_DETAIL.FAIL })
			if (onError) onError()
		}
	}

/* Create */

export const withdrawalsCreate =
	(id: number, values: ICreateWithdrawalFormValues, onSuccess: () => void): ThunkResult<void> =>
	async (dispatch) => {
		dispatch({ type: WITHDRAWALS_CREATE.START })
		try {
			await postReq('/api/v1/users/{userID}/withdrawals/', { userID: id }, { amount: values.amount }, {}, false)
			dispatch({ type: WITHDRAWALS_CREATE.DONE })
			onSuccess()
		} catch (error) {
			dispatch({ type: WITHDRAWALS_CREATE.FAIL })
		}
	}

/* Update status (approve / reject) */

export const withdrawalsUpdateStatus =
	(id: string, status: WITHDRAWAL_STATE.APPROVED | WITHDRAWAL_STATE.REJECTED, onSuccess: (newWithdrawalID: string) => void): ThunkResult<void> =>
	async (dispatch) => {
		dispatch({ type: WITHDRAWALS_UPDATE_STATUS.START })
		try {
			const { data } = await patchReq(`/api/admin/withdrawals/{withdrawalID}`, { withdrawalID: id }, { withdrawalStatus: status }, {}, false)
			dispatch({ type: WITHDRAWALS_UPDATE_STATUS.DONE })
			onSuccess(data.withdrawalID)
		} catch (error) {
			dispatch({ type: WITHDRAWALS_UPDATE_STATUS.FAIL })
		}
	}

/* Actions */

export type IWithdrawalsAction = IWithdrawalsLoadListAction | IWithdrawalsLoadListByUserAction | IWithdrawalsLoadDetailAction
