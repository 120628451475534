import { Col, Row } from 'antd'
import { FC, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import Breadcrumb from '../../atoms/Breadcrumb'
import { employeesLoadDetail } from '../../redux/employees/employeesActions'
import WithdrawalDetailHistory from '../withdrawals/detail/WithdrawalDetailHistory'
import WithdrawalDetailRequest from '../withdrawals/detail/WithdrawalDetailRequest'
import EmployeeAccountInfoForm from './detail/EmployeeAccountInfoForm'
import EmployeeWithdrawalInfoForm from './detail/EmployeeWithdrawalInfoForm'
import { EMPLOYEES_LOAD_DETAIL } from '../../redux/employees/employeesTypes'
import { WITHDRAWALS_LOAD_DETAIL, WITHDRAWALS_LOAD_LIST, WITHDRAWALS_LOAD_LIST_BY_USER } from '../../redux/withdrawals/withdrawalsTypes'

const EmployeeDetail: FC = () => {
	const { id } = useParams()
	const { t } = useTranslation()
	const navigate = useNavigate()
	const dispatch = useDispatch()

	useEffect(() => {
		document.title = `${t('loc:admin|Employee')} - ${t('loc:general|WageNow')}`
	})

	useEffect(() => {
		if (id) {
			dispatch(
				employeesLoadDetail(Number(id), undefined, () => {
					navigate(t('paths:employees'), { replace: true })
				})
			)
		}
	}, [dispatch, id, navigate, t])

	useEffect(() => {
		return () => {
			dispatch({ type: EMPLOYEES_LOAD_DETAIL.RESET })
			dispatch({ type: WITHDRAWALS_LOAD_DETAIL.RESET })
			dispatch({ type: WITHDRAWALS_LOAD_LIST.RESET })
			dispatch({ type: WITHDRAWALS_LOAD_LIST_BY_USER.RESET })
		}
	}, [])

	return (
		<>
			<Breadcrumb path={t('paths:employees')} label={t('loc:admin|All employees')} />
			<Row gutter={{ lg: 32, xxl: 64 }}>
				<Col xs={{ span: 24 }} lg={{ span: 14 }} xl={{ span: 16 }}>
					<WithdrawalDetailRequest />
					<WithdrawalDetailHistory />
				</Col>
				<Col xs={{ span: 24 }} lg={{ span: 10 }} xl={{ span: 8 }}>
					<EmployeeWithdrawalInfoForm />
					<EmployeeAccountInfoForm />
				</Col>
			</Row>
		</>
	)
}

export default EmployeeDetail
