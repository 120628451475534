import { FC, useEffect, useState } from 'react'
import { Spin } from 'antd'
import { useTranslation } from 'react-i18next'
import qs from 'qs'
import { useLoadScript } from '@react-google-maps/api'
import { useDispatch, useSelector } from 'react-redux'
import dayjs from 'dayjs'
import { RootState } from '../../redux'
import WelcomeForm, { IWelcomeFormValues } from './WelcomeForm/WelcomeForm'
import SuccessfullImage from '../../assets/images/new-withdrawal.png'
import { getOnboardingData, IData, postOnboardingData } from '../../redux/onboarding/onboardingActions'

type IFormState = 'init' | 'form' | 'success' | 'error'

function getWelcomeText(onboard?: IData) {
	if (onboard?.companyContractFlow === 'CONTRACT_BEFORE_REGISTRATION') {
		return 'loc:general|Welcome to WageNow. We will send you an electronic contract for signature to your email. After signing, your Wagenow account will be automatically created and we will send instructions for setting the password to the e-mail address you selected.'
	}
	return 'loc:general|Instructions for creating a password and a contract for signing have been sent to your chosen e-mail'
}

const WelcomePage: FC = () => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const { isLoaded } = useLoadScript({
		/* eslint-disable no-underscore-dangle */
		googleMapsApiKey: window?.__RUNTIME_CONFIG__?.REACT_APP_PLACES_KEY
			? window.__RUNTIME_CONFIG__.REACT_APP_PLACES_KEY
			: process.env.REACT_APP_PLACES_KEY || '',
		/* eslint-enable no-underscore-dangle */
		libraries: ['places']
	})

	const [formState, setFormState] = useState<IFormState>('init')
	const { hash } = qs.parse(document.location.search, { ignoreQueryPrefix: true })
	const { data: onboard } = useSelector((state: RootState) => state.onboarding)

	useEffect(() => {
		document.title = `${t('loc:general|Welcome in Wagenow')}`
	}, [])

	useEffect(() => {
		if (hash) {
			dispatch(
				getOnboardingData(
					hash as string,
					() => setFormState('form'), // on success
					() => setFormState('error') // on error
				)
			)
		} else {
			setFormState('error')
		}
	}, [dispatch, hash])

	const handleSubmit = (values: IWelcomeFormValues) => {
		const submitValues = {
			businessName: values.businessName?.trim(),
			businessRegistrationNumber: values.businessRegistrationNumber,
			firstName: values.firstName?.trim(),
			lastName: values.lastName?.trim(),
			phoneNumber: `${values.phonePrefix || '+421'}${values.phoneNumber?.replace(/\s/g, '')}`,
			email: values.email?.trim(),
			bankAccountNumber: values.bankAccountNumber?.replace(/\s/g, ''),
			birthday: dayjs(values.birthday),
			employeeIdentifier: values.employeeIdentifier?.trim(),
			address: {
				city: values.city,
				zipCode: values.zipCode,
				street: `${values.street} ${values.houseNumber}`
			}
		}
		dispatch(
			postOnboardingData(submitValues, hash as string, () => {
				setFormState('success')
			})
		)
	}

	const replacePlaceholders = (template: string | undefined, replacements: any) => {
		return template?.replace(/%%(\w+)%%/g, (match, key) => {
			return Object.prototype.hasOwnProperty.call(replacements, key) ? replacements[key] : ''
		})
	}

	const renderState = (state: IFormState) => {
		const textReplacements = {
			company_name: onboard?.name
		}

		const replacedParagraph = replacePlaceholders(onboard?.onboardingForm.paragraph, textReplacements)

		switch (state) {
			case 'init':
				return (
					<div className={'w-100vw h-100vh flex justify-center items-center'}>
						<Spin size={'large'} />
					</div>
				)
			case 'form':
			case 'success':
				return (
					<>
						<div
							className={'h-[250px] md:h-[370px] lg:h-[650px] w-[100vw] top-0 right-0 left-0 absolute bg-cover bg-center bg-no-repeat'}
							style={{ backgroundImage: `url('${onboard?.onboardingForm?.bgPicture}')` }}
						/>
						<div
							className={'pt-[180px] md:pt-[300px] lg:pt-[450px] justify-center flex items-center flex-col min-h-100vh'}
							style={{ backgroundColor: onboard?.onboardingForm?.bgColor }}
						>
							<div className={'justify-center flex items-center ml-auto mr-auto w-100%'}>
								<div
									className={
										'bg-white rounded-[16px] sm:rounded-[32px] lg:rounded-[80px] lg:p-[110px] p-[16px] sm:p-[32px] mb-[110px] w-[850px] z-10 ml-[16px] mr-[16px]'
									}
								>
									{state === 'success' && (
										<>
											<div className={`flex w-full justify-center`}>
												<img
													className={'relative h-[200px] w-[200px] md:h-[350px] md:w-[350px] mb-[48px]'}
													src={SuccessfullImage}
													alt={'Successful'}
												/>
											</div>
											<h1 className={'text-28 font-700 mb-4'}>{onboard?.onboardingForm.title || t('loc:general|Welcome in Wagenow')}</h1>
											<p>{t(getWelcomeText(onboard))}</p>
										</>
									)}
									{state === 'form' && (
										<>
											<header className={'text-left'}>
												{/* <h1 className={'text-40 font-700 mb-4'}>{data?.name}</h1> */}
												<img
													className={'relative h-[40px] sm:h-[68px] w-auto sm:max-w-[500px] z-10 mb-1 sm:mb-3'}
													src={onboard?.onboardingForm.logo}
													alt={''}
												/>
												<h2 className={'text-36 font-700 mb-4'}>{t('loc:general|Welcome in Wagenow')}</h2>
												<p>{replacedParagraph}</p>
											</header>
											<WelcomeForm onSubmit={handleSubmit} />
										</>
									)}
								</div>
							</div>
						</div>
						{/* NOTE: only on form view, set body background to form bg color, beter looking smooth scrolling spring */}
						<style>{`body {background: ${onboard?.onboardingForm.bgColor}}`}</style>
					</>
				)
			case 'error':
				return (
					<div className={'w-100vw h-100vh flex justify-center items-center flex-col p-4'}>
						<h1 className={'text-40 font-700 mb-4'}>{'WageNow'}</h1>
						<p className={'text-center'}>{t('loc:general|The form with the specified address is not available')}</p>
						<p className={'text-center'}>
							{t('loc:general|If you received this address from your employer, please contact him directly or contact us at')}
							{' info@wagenow.sk'}
						</p>
					</div>
				)
			default:
				return (
					<div className={'w-100vw h-100vh flex justify-center items-center'}>
						<Spin size={'large'} />
						<p className={'text-center'}>{'Načítavam...'}</p>
					</div>
				)
		}
	}

	return isLoaded ? renderState(formState) : null
}

export default WelcomePage
