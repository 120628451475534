import { ThunkResult } from '..'
import { ICreateAdminFormValues } from '../../layouts/admin/CreateAdminForm'
import { postReq } from '../../utils/requests'
import { mapAdminsCreateRequest } from './adminsHelpers'
import { ADMINS_CREATE } from './adminsTypes'

/* Create */

// eslint-disable-next-line import/prefer-default-export
export const adminsCreate =
	(values: ICreateAdminFormValues, onSuccess: () => void): ThunkResult<void> =>
	async (dispatch) => {
		dispatch({ type: ADMINS_CREATE.START })
		try {
			await postReq('/api/admin/users/', undefined, mapAdminsCreateRequest(values), { headers: { 'accept-language': 'sk' } }, false)
			dispatch({ type: ADMINS_CREATE.DONE })
			onSuccess()
		} catch (error) {
			dispatch({ type: ADMINS_CREATE.FAIL })
		}
	}
