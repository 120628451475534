import { IStoreAction, STORE } from '../generalTypes'
import { ICompaniesAction, ICompaniesLoadListPayload } from './companiesActions'
import { COMPANIES_LOAD_LIST } from './companiesTypes'

const initState: {
	list: ICompaniesLoadListPayload
} = {
	list: {
		records: undefined,
		pagination: undefined,
		isLoading: false,
		isFailure: false
	}
}

export default (state = initState, action: ICompaniesAction | IStoreAction) => {
	switch (action.type) {
		case COMPANIES_LOAD_LIST.START:
			return {
				...state,
				list: {
					...state.list,
					isLoading: true,
					isFailure: false
				}
			}
		case COMPANIES_LOAD_LIST.DONE:
			return {
				...state,
				list: {
					...state.list,
					...action.payload,
					isLoading: false,
					isFailure: false
				}
			}
		case COMPANIES_LOAD_LIST.FAIL:
			return {
				...state,
				list: {
					...state.list,
					isLoading: false,
					isFailure: true
				}
			}
		case STORE.RESET:
			return initState
		default:
			return state
	}
}
