import { Col, Row } from 'antd'
import { debounce } from 'lodash'
import { ChangeEvent, FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import InputField from '../../../atoms/form/InputField'
import SelectField from '../../../atoms/form/SelectField'
import { RootState } from '../../../redux'
import { ICompaniesLoadListPayload } from '../../../redux/companies/companiesActions'
import { ICompany, IPaginationResponse } from '../../../types/interfaces'
import { TABLE_ORDER, USERS_CONTRACT_STATUS, WITHDRAWAL_STATE } from '../../../utils/enums'
import { getFilterCompanyOptions, getFilterOrderOptions, getFilterWithdrawalStateOptions } from '../../../utils/helpers'

export interface IWithdrawalsListFilterValues {
	page?: IPaginationResponse['page']
	limit?: IPaginationResponse['limit']
	search?: string
	state?: WITHDRAWAL_STATE
	contractState?: USERS_CONTRACT_STATUS
	company?: ICompany['id']
	order?: TABLE_ORDER
}

export const withdrawalsListFilterDefaultState: IWithdrawalsListFilterValues = {
	page: 1,
	limit: 10
}

interface IWithdrawalsListFilter {
	filter: IWithdrawalsListFilterValues
	setFilter: (filter: IWithdrawalsListFilterValues) => void
}

const WithdrawalsListFilter: FC<IWithdrawalsListFilter> = ({ filter, setFilter }) => {
	const { t } = useTranslation()

	const companies: ICompaniesLoadListPayload = useSelector((state: RootState) => state.companies.list)

	const [searchValue, setSearchValue] = useState(filter.search)

	const debouncedSearch = debounce((value) => setFilter({ ...filter, page: 1, search: value || undefined }), 250)

	return (
		<Row
			gutter={[
				{ xs: 8, sm: 8, md: 16, lg: 20 },
				{ xs: 8, sm: 8, md: 16, lg: 20 }
			]}
			className={'mb-8 lg:mb-16'}
		>
			<Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6 }}>
				<InputField
					type={'search'}
					placeholder={t('loc:admin|Search Employee')}
					input={{
						name: 'search',
						value: searchValue,
						onChange: (event: ChangeEvent<HTMLInputElement>) => {
							setSearchValue(event.target.value)
							debouncedSearch(event.target.value)
						}
					}}
					meta={{ form: 'withdrawals-list-filter' }}
					inputClassName={'bg-white'}
				/>
			</Col>
			<Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6 }}>
				<SelectField
					placeholder={t('loc:admin|State')}
					options={getFilterWithdrawalStateOptions()}
					input={{
						name: 'state',
						value: filter.state,
						onChange: (value) => setFilter({ ...filter, page: 1, state: value })
					}}
					meta={{ form: 'withdrawals-list-filter' }}
					inputClassName={'bg-white'}
				/>
			</Col>
			<Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 6 }}>
				<SelectField
					placeholder={t('loc:admin|Company')}
					options={getFilterCompanyOptions(companies.records)}
					loading={companies.isLoading}
					disabled={!companies.isLoading && companies.isFailure}
					input={{
						name: 'company',
						value: filter.company,
						onChange: (value) => setFilter({ ...filter, page: 1, company: value })
					}}
					meta={{ form: 'withdrawals-list-filter' }}
					inputClassName={'bg-white'}
				/>
			</Col>
			<Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 6 }}>
				<SelectField
					placeholder={t('loc:admin|Order by')}
					options={getFilterOrderOptions()}
					input={{
						name: 'search',
						value: filter.order,
						onChange: (value) => setFilter({ ...filter, page: 1, order: value })
					}}
					meta={{ form: 'withdrawals-list-filter' }}
					inputClassName={'bg-white'}
				/>
			</Col>
		</Row>
	)
}

export default WithdrawalsListFilter
